import { Login } from 'pages/Authentication';
import { Routes, Route } from "react-router-dom";

const LoginRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path="*" element={<Login />} />
    </Routes>
  )
}

export default LoginRoutes