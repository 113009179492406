import { useReducer } from "react";
import { initialState } from "./Groups.model";
import { GROUPS_ACTIONS } from "./Groups.actions";
import { GroupsReducer } from "./Groups.reducers";
import { GroupsServices } from "./Groups.services";

export const useGroupsViewInitialization = () => {
  const [state, dispatch] = useReducer(GroupsReducer, initialState);
  const services = GroupsServices();
  const actions = GroupssActions(dispatch, services);
  return { state, actions };
};

const GroupssActions = (dispatch, services) => {
  /** API Call: Get all groups list */
  const actions = {
    getAllGroups: async () => {
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAllGroups();
      if (response?.error) {
        dispatch({
          type: GROUPS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: GROUPS_ACTIONS.GROUPSLIST,
          payload: response?.data ?? [],
        });
      }
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: false,
      });
    },
    changeViewStatus: (status, selected_group) => {
      dispatch({
        type: GROUPS_ACTIONS.SELECTEDVIEW,
        payload: { status: status, group: selected_group },
      });
    },
    addNewGroup: async (groupInfo, status, onSuccessCallBack) => {
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.addNewGroup(groupInfo);
      if (response?.error) {
        dispatch({
          type: GROUPS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: GROUPS_ACTIONS.SELECTEDVIEW,
          payload: { status: status, group: null },
        });
        if (response.data) {
          onSuccessCallBack(response.data);
        }
      }
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: false,
      });
    },
    editGroupInfo: async (groupInfo, status, onSuccessCallBack) => {
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.editGroup(groupInfo);
      if (response?.error) {
        dispatch({
          type: GROUPS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        if (response.data) {
          onSuccessCallBack(response.data);
        }
        dispatch({
          type: GROUPS_ACTIONS.SELECTEDVIEW,
          payload: { status: status, group: null },
        });
      }
      dispatch({
        type: GROUPS_ACTIONS.LOADING,
        payload: false,
      });
    },
    updateGroupsList: (group, groupsList) => {
      const index = groupsList.findIndex(
        (item) => item.groupId === group.groupId
      );
      if (index > -1) {
        groupsList[index] = group;
      } else {
        groupsList.push(group);
      }
      dispatch({
        type: GROUPS_ACTIONS.GROUPSLIST,
        payload: groupsList,
      });
    },
    refreshError: () => {
      dispatch({
        type: GROUPS_ACTIONS.ERROR,
        payload: null,
      });
    },
    fetchKafkaTopicsAndConfigurationsInfo: async (selected_group) => {
      const [kafkaTopics, configurations] =
        await services.getKafkaAndConfigurationsInfo(selected_group.groupId);
      selected_group["kafkaTopics"] = kafkaTopics?.data
        ? kafkaTopics?.data?.sort((a, b) => {
            if (a.kafkaPublisher < b.kafkaPublisher) return -1;
            if (a.kafkaPublisher > b.kafkaPublisher) return 1;
            return 0;
          })
        : [];
      selected_group["configurations"] = configurations?.data ? configurations?.data.sort((a, b) => {
        if (a.databaseType < b.databaseType) return -1;
        if (a.databaseType > b.databaseType) return 1;
        return 0;
      }) : [];

      dispatch({
        type: GROUPS_ACTIONS.SELECTEDGROUP,
        payload: selected_group,
      });
    },
    editKafkaTopic: async (topic, selected_group, erroCallbackMethod) => {
      const response = await services.editKafkaTopic(topic);
      if (response.status === 200) {
        selected_group["kafkaTopics"] = response?.data ? response?.data?.sort((a, b) => {
          if (a.kafkaPublisher < b.kafkaPublisher) return -1;
          if (a.kafkaPublisher > b.kafkaPublisher) return 1;
          return 0;
        }) : [];
        dispatch({
          type: GROUPS_ACTIONS.SELECTEDGROUP,
          payload: selected_group,
        });
      } else {
        erroCallbackMethod(response.error);
      }
    },
    editConfigurationSchemas: async (
      schemaInfo,
      selected_group,
      erroCallbackMethod
    ) => {
      const response = await services.editConfigurationSchema(schemaInfo);
      if (response.status === 200) {
        selected_group["configurations"] =
          response?.data ?? selected_group.configurations;
        dispatch({
          type: GROUPS_ACTIONS.SELECTEDGROUP,
          payload: selected_group,
        });
      } else {
        erroCallbackMethod(response.error);
      }
    },
    searchGroups: (searchString) => {
      dispatch({
        type: GROUPS_ACTIONS.SEARCHGROUP,
        payload: searchString,
      });
    },
  };
  return actions;
};
