import { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import UserContext from "common/Context/UserContext";
import placeholderImage from "../../../../../../assets/place_holder.svg";

const UpdateOrPreviewImage = ({
  imageData,
  show,
  handleCloseAction,
  isPreview = true,
}) => {
  const [formData, setFormData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [imgPosition, setImgPosition] = useState({ x: 0, y: 0 });
  const { userProfile } = useContext(UserContext);
  
  useEffect(() => {
    if (!formData) {
      const updatedData = {...imageData}
      updatedData["modifiedBy"] = userProfile.profile.name
      setFormData(updatedData);
    }
  }, [imageData]);

  const getImageStyles = () => {
    const scale = `scale(${zoomLevel})`;
    const maxWidth = zoomLevel > 1.5 ? "150%" : "100%";
    const maxHeight = zoomLevel > 1.5 ? "150%" : "100%";

    return {
      transform: `${scale} translate(${imgPosition.x}px, ${imgPosition.y}px)`,
      transition: "transform 0.25s ease-in-out",
      maxWidth,
      maxHeight,
    };
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    let newValue;
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files[0];
    } else {
      newValue = value;
    }
    setFormData((prev) => ({ ...prev, [name]: newValue }));

    if (type === "file") {
      if (validateFile(files[0])) {
        setFormData((prev) => ({
          ...prev,
          thingImageFilename: files[0]?.name ?? "",
        }));
      }
    }
  };

  const validateFile = (file) => {
    if (!file) return true;
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const isValidType = validTypes.includes(file.type);
    const isValidSize = file.size <= 1048576; // 1MB
    const errors = {};
    if (!isValidType) {
      errors.image = "File type must be JPG, JPEG, or PNG";
    }
    if (!isValidSize) {
      errors.image = "File size must be less than or equal to 1MB";
    }
    setFormData((prev) => ({ ...prev, errors }));
    return Object.keys(errors).length === 0;
  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => prev + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.2) {
      setZoomLevel((prev) => prev - 0.1);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const dx = e.clientX - startPosition.x;
    const dy = e.clientY - startPosition.y;
    setImgPosition((prev) => ({
      x: prev.x + dx,
      y: prev.y + dy,
    }));
    setStartPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleCloseAction(null);
      }}
      centered
      size="lg"
    >
      <Modal.Body>
        <div className="col-12 row">
          <div className="form-group col-sm-12 row">
            <div className="col-4">
              <label htmlFor="thingImageName">Image Name</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-7">
              <input
                id="thingImageName"
                className="form-control"
                type="text"
                name="thingImageName"
                value={formData?.thingImageName ?? ""}
                onChange={handleChange}
                required={true}
                readOnly={isPreview}
                autoComplete="off"
                autoCorrect="off"
              />
            </div>
          </div>
          <div className="form-group toggle col-12 row">
            <div className="col-4">
              <label htmlFor="isActive">Active</label>
            </div>
            <div className="col-1">:</div>
            <div className="form-check form-switch col-7">
                <input
                  name="isActive"
                  className="form-check-input custom-switch"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={formData?.isActive ?? false}
                  onChange={handleChange}
                />
              </div>
          </div>


          <div className="form-group col-sm-12 row">
            <div className="col-4">
              <label htmlFor="thingImageFilename">Image Filename</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-7">
              <input
                id="thingImageFilename"
                type="text"
                className="form-control"
                value={formData?.thingImageFilename ?? ""}
                readOnly
              />
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-4"></div>
            <div className="col-8" style={{ textAlign: 'end' }}>
              <Button variant="outline-secondary" style={{ border: 'none' }} onClick={handleZoomIn}>
                <i className="bi bi-zoom-in"></i>
              </Button>
              <Button variant="outline-secondary" style={{ border: 'none' }} onClick={handleZoomOut}>
                <i className="bi bi-zoom-out"></i>
              </Button>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-4"></div>
            <div className="col-8"
              style={{ textAlign: "center", overflow: "auto", maxHeight: "80vh", scrollbarWidth: 'thin' }}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}>
              <img
                src={formData?.thingImage ? `${`data:image/png;base64,${formData?.thingImage}`}` : placeholderImage}
                alt="Preview"
                className="model-img-min"
                style={getImageStyles()} onMouseDown={handleMouseDown} onDragStart={(e) => e.preventDefault()} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ marginTop: "10px" }}></div>
        {!isPreview && (
          <Button
            variant="primary"
            onClick={() => {
              handleCloseAction(formData);
            }}
          >
            Update
          </Button>
        )}
        <Button variant="secondary" onClick={() => { handleCloseAction(null); }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UpdateOrPreviewImage;