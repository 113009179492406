import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "/keycloak/auth",
  realm: "waterminds",
  clientId: "user-access",
});


const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "login-required",
      checkLoginIframe: true,
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      if (authenticated) {
        keycloak
          .loadUserProfile()
          .then((profile) => {
            localStorage.setItem("keycloak-token", keycloak.token);
            localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);
            onAuthenticatedCallback(profile);
          })
          .catch(() => {
            alert("Failed to fetch user profile");
          });
      } else {
        window.location.reload();
      }
    })
    .catch((error) => {
      alert(`Failed to initialize Keycloak: ${error?.error ?? "Keycloak error"}`);
    });
};
const handleTokenExpiration = () => {
  keycloak.onAuthLogout = () => {
    clearKeycloakData();
    window.location.reload();
  };
};

const clearKeycloakData = () => {
  localStorage.removeItem("keycloak-token");
  localStorage.removeItem("keycloak-refresh-token");
};

export { keycloak, initKeycloak, clearKeycloakData, handleTokenExpiration };
