import { getRequest } from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

const compareArraysAndMarkEdited = (sourceArray, editedArray, modifiedBy) => {
  return editedArray.map((item2, index) => {
    if (index < sourceArray.length) {
      const item1 = sourceArray[index];
      // Check if there are any differences between items
      const isEdited = Object.keys(item1).some(
        (key) => item1[key] !== item2[key]
      );
      // Return the item with the edited key if differences are found
      return isEdited ? { ...item2, modifiedBy: modifiedBy } : item2;
    }
    // If index is out of bounds of sourceArray, just return item2 without modification
    return item2;
  });
};

const getUserAccessInformation = async () => {
  const service_path = INFRAMARK_CONSTANTS.login.services;
  const response = await getRequest(`${service_path.userAccessInfo}`);
  return response;
};
function searchHierarchy(tree, searchString) {
  return tree
    .map((node) => {
      // Check if the current node matches the search string
      const isMatch = node.thing_name
        .toLowerCase()
        .includes(searchString.toLowerCase());
      // Recursively filter the children
      const filteredChildren = node.children
        ? searchHierarchy(node.children, searchString)
        : [];
      // Include this node if it's a match or if any of its children match
      if (isMatch || filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren, // Update children to only include the filtered ones
        };
      }
      // Otherwise, return null (no match found)
      return null;
    })
    .filter((node) => node !== null); // Remove any null nodes
}
function base64ToBlob(base64, type = 'application/octet-stream') {
  // Remove the prefix (if it exists)
  const byteCharacters = atob(base64);
  const byteNumbers = new Uint8Array(byteCharacters.length);
  
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  
  return new Blob([byteNumbers], { type: type });
}
export { compareArraysAndMarkEdited, getUserAccessInformation, searchHierarchy, base64ToBlob };
