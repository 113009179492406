import React, { useEffect, useState, useContext } from "react";
import "./AddEditThingType.style.css";
import { Tabs, Tab } from "react-bootstrap";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import UserContext from "common/Context/UserContext";
import CreatableSelect from "react-select/creatable";
import ThingTypeImage from "../ThingTypeImage/ThingTypeImage";

const AddEditThingType = ({
  selectedView,
  selectedThingType,
  handleCancel,
  isEdit = false,
  addNewThingType,
  updateThingType,
  fetchAllImages,
  addNewThingTypeImage,
  updateThingTypeImage,
}) => {
  const Constants = INFRAMARK_CONSTANTS.thingTypeDetails.constants;
  const categoryTableHeaders = isEdit ? INFRAMARK_CONSTANTS.thingTypeDetails.tableHeaders.category : INFRAMARK_CONSTANTS.thingTypeDetails.tableHeaders.category.filter((item) => item.title !== "Actions")
  const alertResponseTableHeaders = isEdit ? INFRAMARK_CONSTANTS.thingTypeDetails.tableHeaders.alertResponse : INFRAMARK_CONSTANTS.thingTypeDetails.tableHeaders.alertResponse.filter((item) => item.title !== "Actions")
  const [activeTab, setActiveTab] = useState("Category");
  const [isEditable, setIsEditable] = useState(false);
  const { userProfile } = useContext(UserContext);
  const viewTitle =
    selectedView === Constants.ADDTHINGTYPEVIEW
      ? "Create Thing Type"
      : "Update Thing Type";
  const alertResponse = INFRAMARK_CONSTANTS.thingTypeDetails.alertResponse;
  const pagesInfo = INFRAMARK_CONSTANTS.thingTypeDetails.displayPages;
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      thingType: {
        thingType: "",
        thingTypeId: "",
        displayPage: pagesInfo[0],
        isActive: true,
      },
      thingTypeCategories: [],
      alertResponses: [],
    },
  });

  const {
    fields: categoryFields,
    append: appendCategory,
    remove: removeCategory,
  } = useFieldArray({
    control,
    name: "thingTypeCategories",
  });

  const {
    fields: responseFields,
    append: appendResponse,
    remove: removeResponse,
  } = useFieldArray({
    control,
    name: "alertResponses",
  });

  useEffect(() => {
    loadInitialValues();
  }, [selectedThingType]);

  function loadInitialValues() {
    setIsEditable(true);
    if (selectedThingType) {
      if (selectedView === Constants.THINGTYPEPREVIEW) {
        setIsEditable(false);
      }
      const typeCategories = selectedThingType.thingTypeCategories?.map(
        (category) => {
          category["modifiedBy"] = userProfile.profile.userid;
          return category;
        }
      );
      const responses = selectedThingType.alertResponses?.map((response) => {
        response["modifiedBy"] = userProfile.profile.userid;
        return response;
      });
      reset({
        thingType: {
          thingTypeId: selectedThingType.thingTypeId,
          thingType: selectedThingType.thingType,
          isActive: selectedThingType.isActive,
          displayPage: selectedThingType.displayPage
            ? {
                label: selectedThingType.displayPage,
                value: selectedThingType.displayPage,
              }
            : pagesInfo[0],
          modifiedBy: userProfile.profile.userid,
        },
        thingTypeCategories: typeCategories ?? [],
        alertResponses: responses ?? [],
      });
    } else {
      reset({
        thingType: {
          thingType: "",
          thingTypeId: "",
          displayPage: pagesInfo[0],
          isActive: true,
          createdBy: userProfile.profile.userid,
        },
        thingTypeCategories: [],
        alertResponses: [],
      });
    }
  }

  const loadNoData = () => {
    return isEditable ? (
      <div className="mt-2 button-empty-row">
        <button
          type="button"
          className="btn-add btn-add-row"
          onClick={() => {
            if (activeTab === "Category") {
              appendCategory({
                thingTypeCategory: "",
                isActive: true,
                createdBy: userProfile.profile.userid,
              });
            } else {
              appendResponse({
                alertResponse: alertResponse[0].value,
                alertResponseMessage: "",
                isActive: true,
                createdBy: userProfile.profile.userid,
              });
            }
          }}
        >
          {`+ Add ${activeTab}`}
        </button>
      </div>
    ) : (
      <h5>{activeTab} list not available</h5>
    );
  };

  const onSubmit = (data) => {
    if (
      data.thingType.displayPage &&
      typeof data.thingType.displayPage === "object"
    ) {
      data.thingType.displayPage = data.thingType.displayPage.value;
    }
    if (data.thingTypeCategories.length === 0) {
      data.thingTypeCategories = [
        {
          thingTypeCategory: data.thingType.thingType,
          shortDescription:data.thingType.thingType,
          isActive: true,
          createdBy: userProfile.profile.userid,
        },
      ];
    }
    const thingTypeCategoriesArray = data.thingTypeCategories.filter(
      (category) => category.thingTypeCategory.length > 0
    );
    data.thingTypeCategories = thingTypeCategoriesArray;
    if (selectedView === Constants.EDITTHINGTYPEVIEW) {
      updateThingType(data);
    } else {
      addNewThingType(data);
    }
  };

  // Function to get the label for a given value
  const getLabelForValue = (value) => {
    const found = alertResponse.find((item) => item.value === value);
    return found ? found.label : "";
  };

  const handleUploadImage = (image_data) => {
    setValue("images", image_data);
  };

  return (
    <div className="col-12 row">
      <div className="div-breadcrum-style" style={{ marginLeft: "-7px" }}>
        {isEdit ? (
          <h5 className="mb-0 ml-2">{viewTitle}</h5>
        ) : (
          <ol className="breadcrumb breadcrum-margin" style={{ margin: 0 }}>
            <li className="breadcrumb-item">
              <button
                className="breadcrumb-item-custom btn-breadcrum"
                onClick={handleCancel}
              >
                Thing Type
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Thing Type Details
            </li>
          </ol>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 mb-4 mt-4 ">
          <div className="col-sm-12 row mb-4">
            <div className="col-sm-2">
              <label htmlFor="checkbox"> Active</label>
            </div>

            <div className="col-sm-7 toggle">
              <label className="switch">
                <input
                  type="checkbox"
                  {...register("thingType.isActive")}
                  disabled={!isEditable}
                />
                {""}
                <span className="slider"></span>
              </label>
            </div>
          </div>

          <div className="col-sm-12 row mb-4">
            <div className="col-sm-2">
              <label htmlFor="thingType">Thing Type</label>
            </div>

            <div className="col-sm-8">
              <input
                id="thingType"
                className="form-control col-6"
                type="text"
                data-testid="thing-type-input"
                style={{
                  borderColor: errors?.thingType?.thingType ? "red" : "",
                }}
                {...register("thingType.thingType", { required: true })}
                disabled={!isEditable}
                autoComplete="off"
                autoCorrect="off"
              />
            </div>
          </div>
          <div className="col-sm-12 row">
            <div className="col-sm-2">
              <label htmlFor="displaypage">Display Page</label>
            </div>

            <div className="col-sm-4" aria-labelledby="display-page-label">
              <Controller
                name="thingType.displayPage"
                control={control}
                render={({ field }) => (
                  <>
                    {!isEditable ? (
                      <input
                        type="text"
                        className="form-control  Asset-text-decor-caps"
                        data-testid="display-page-input"
                        value={field.value?.value ?? ""}
                        readOnly
                      />
                    ) : (
                      <CreatableSelect
                        {...field}
                        isClearable
                        options={pagesInfo}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption); // Ensure react-hook-form updates its value
                        }}
                      />
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>

        <div className= {`bottom-section ${getValues("thingType.isActive") || (selectedView === Constants.THINGTYPEPREVIEW) ? "" : 'disabled'} table-tab-container`}>
          <div className="header-admin-thingtype">
            <Tabs
              id="thing-type-tabs"
              className="tab-padding"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Tab eventKey="Category" title="Categories">
                <table>
                  <thead>
                    <tr>
                      {categoryTableHeaders.map((headerItem)=><th className={`${headerItem.width}`} key={headerItem.title}>{headerItem.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {categoryFields.map((category, index) => (
                      <tr key={category.id}>
                        <td className="col-4">
                          <input
                            type="text"
                            className="form-control"
                            {...register(
                              `thingTypeCategories.${index}.thingTypeCategory`,
                              { required: false }
                            )}
                            maxLength={255}
                            disabled={!isEditable}
                            autoComplete="off"
                            autoCorrect="off"
                          />
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            className="form-control"
                            {...register(
                              `thingTypeCategories.${index}.shortDescription`,
                              { required: false }
                            )}
                            maxLength={255}
                            disabled={!isEditable}
                            autoComplete="off"
                            autoCorrect="off"
                          />
                        </td>
                        <td className="col-1">
                          <div className="toggle">
                            <label className="switch">
                              <input
                                type="checkbox"
                                {...register(
                                  `thingTypeCategories.${index}.isActive`
                                )}
                                disabled={!isEditable}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </td>
                        {isEdit && (
                          <td className="col-1" colSpan={2}>
                            <button
                              disabled={
                                isEdit &&
                                selectedThingType?.thingTypeCategories[index]
                                  ?.thingTypeCategory
                              }
                              className="btn-admin-thing"
                              data-testid="trash-button"
                              onClick={() => {
                                removeCategory(index);
                              }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{
                                  color:
                                    isEdit &&
                                    selectedThingType?.thingTypeCategories[
                                      index
                                    ]?.thingTypeCategory
                                      ? "grey"
                                      : "red",
                                }}
                              ></i>
                            </button>
                            {index === categoryFields.length - 1 && (
                              <button
                                className="btn-admin-thing"
                                onClick={() => {
                                  appendCategory({
                                    thingTypeCategory: "",
                                    isActive: true,
                                    createdBy: userProfile.profile.userid,
                                  });
                                }}
                              >
                                <i
                                  className="bi bi-plus-circle h5"
                                  style={{
                                    color: "green",
                                  }}
                                ></i>
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                    {categoryFields.length <= 0 && (
                      <tr>
                        <td colSpan={4} className="td-empty-row">
                          {loadNoData()}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="Response" title="Alert Response">
                <table>
                  <thead>
                    <tr>
                      {alertResponseTableHeaders.map((headerItem) => <th className={`${headerItem.width}`} key={headerItem.title}>{headerItem.title}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {responseFields.map((alert, index) => (
                      <tr key={alert.id}>
                        <td className="col-2">
                          <Controller
                            name={`alertResponses.${index}.alertResponse`}
                            control={control}
                            render={({ field }) => (
                              <>
                                {!isEditable ? (
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={getLabelForValue(field.value)}
                                    readOnly
                                  />
                                ) : (
                                  <select {...field} className="form-control">
                                    {alertResponse.map((option, index) => (
                                      <option key={index} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              </>
                            )}
                          />
                        </td>
                        <td className="col-6">
                          <input
                            type="text"
                            className="form-control"
                            {...register(
                              `alertResponses.${index}.alertResponseMessage`,
                              { required: false }
                            )}
                            disabled={!isEditable}
                            maxLength={50}
                            autoComplete="off"
                            autoCorrect="off"
                          />
                        </td>
                        <td className="col-1">
                          <div className="toggle">
                            <label className="switch">
                              <input
                                type="checkbox"
                                {...register(
                                  `alertResponses.${index}.isActive`
                                )}
                                disabled={!isEditable}
                              />
                              <span className="slider"></span>
                            </label>
                          </div>
                        </td>
                        {isEdit && (
                          <td className="col-1" colSpan={2}>
                            <button
                              className="btn-admin-thing"
                              disabled={
                                isEdit &&
                                selectedThingType?.alertResponses[index]
                                  ?.alertResponse
                              }
                              onClick={() => {
                                removeResponse(index);
                              }}
                            >
                              <i
                                className="bi bi-trash3"
                                style={{
                                  color:
                                    isEdit &&
                                    selectedThingType?.alertResponses[index]
                                      ?.alertResponse
                                      ? "grey"
                                      : "red",
                                }}
                              ></i>
                            </button>
                            {index === responseFields.length - 1 && (
                              <button
                                className="btn-admin-thing"
                                onClick={() => {
                                  appendResponse({
                                    alertResponse: alertResponse[0].value,
                                    alertResponseMessage: "",
                                    isActive: true,
                                    createdBy: userProfile.profile.userid,
                                  });
                                }}
                              >
                                <i
                                  className="bi bi-plus-circle h5"
                                  style={{
                                    color: "green",
                                  }}
                                ></i>
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                    {responseFields.length <= 0 && (
                      <tr>
                        <td colSpan={4} className="td-empty-row">
                          {loadNoData()}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="ThingImage" title="Images">
                {activeTab !== "ThingImage" ? (
                  <></>
                ) : (
                  <ThingTypeImage
                    selectedView={selectedView}
                    constants={Constants}
                    control={control}
                    selectedThingType={selectedThingType}
                    fetchAllImages={fetchAllImages}
                    addNewThingTypeImage={
                      selectedView === Constants.ADDTHINGTYPEVIEW
                        ? handleUploadImage
                        : addNewThingTypeImage
                    }
                    updateThingTypeImage={updateThingTypeImage}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>

        {isEdit && (
          <div className="col-12 row btn-div-fixed">
            <div className="col-8"></div>
            <div className="col-4 btn-add-edit">
              <button className="btn btn-light btn-cncl" onClick={handleCancel}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddEditThingType;
AddEditThingType.propTypes = {
  selectedView: PropTypes.string.isRequired,
  selectedThingType: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  addNewThingType: PropTypes.func.isRequired,
  updateThingType: PropTypes.func.isRequired,
  fetchAllImages: PropTypes.func.isRequired,
  addNewThingTypeImage: PropTypes.func.isRequired,
};
