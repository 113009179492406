import { postRequest, getRequest } from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
export const CreateUserServices = () => {
  const apiPaths = INFRAMARK_CONSTANTS.users.services;
  const services = {
    /** API Call to create new users */
    createNewUser: async (userInfo) => {
      const response = await postRequest(apiPaths.createUser, userInfo);
      return response
    },
    validateUser: async (userId) => {
      const response = await getRequest(`${apiPaths.validateUser}${userId}`);
      return response;
    }
  };
  return services;
};
