// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  position: fixed; /* Ensure the spinner is fixed in the viewport */
  top: 50%; /* Position it vertically in the middle */
  left: 50%; /* Position it horizontally in the middle */
  transform: translate(-50%, -50%); /* Center the spinner */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--theme-color); /* Change spinner color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}
.spinnerText {
    color: var(--theme-color);
    font-size: 16px;
}


/* CSS animation for the spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/common/Components/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAE,gDAAgD;EACjE,QAAQ,EAAE,yCAAyC;EACnD,SAAS,EAAE,2CAA2C;EACtD,gCAAgC,EAAE,uBAAuB;EACzD,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,oCAAoC;EACpC,qCAAqC,EAAE,8BAA8B;EACrE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,mBAAmB;AACrB;AACA;IACI,yBAAyB;IACzB,eAAe;AACnB;;;AAGA,kCAAkC;AAClC;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-spinner {\n  position: fixed; /* Ensure the spinner is fixed in the viewport */\n  top: 50%; /* Position it vertically in the middle */\n  left: 50%; /* Position it horizontally in the middle */\n  transform: translate(-50%, -50%); /* Center the spinner */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  font-size: 16px;\n  z-index: 9999;\n}\n\n.spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: var(--theme-color); /* Change spinner color here */\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  animation: spin 1s linear infinite;\n  margin-bottom: 10px;\n}\n.spinnerText {\n    color: var(--theme-color);\n    font-size: 16px;\n}\n\n\n/* CSS animation for the spinner */\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
