// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-panel {
  font-family: "InframarkFont", Arial, Helvetica, sans-serif !important;
  margin: 1rem;
  padding-bottom: 4rem;
  margin-top: 0.5rem;
}

.header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions button {
  margin-right: 10px;
}

.search {
  display: flex;
  align-items: center;
}

.search input {
  padding: 5px;
  margin-right: 5px;
  padding-top: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.edit-button:hover {
  color: #007bff;
}

.delete-button:hover {
  color: #dc3545;
}
.btn-user-bg {
  border: none;
  background: none;
}
.btn-user-bg:focus-visible {
  outline: none;
}
.add-new-bg {
  margin-left: 25px;
  border: none;
  background: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Users/Users.styles.css"],"names":[],"mappings":"AAAA;EACE,qEAAqE;EACrE,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;AACA;;EAEE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".admin-panel {\n  font-family: \"InframarkFont\", Arial, Helvetica, sans-serif !important;\n  margin: 1rem;\n  padding-bottom: 4rem;\n  margin-top: 0.5rem;\n}\n\n.header-user {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.actions button {\n  margin-right: 10px;\n}\n\n.search {\n  display: flex;\n  align-items: center;\n}\n\n.search input {\n  padding: 5px;\n  margin-right: 5px;\n  padding-top: 0;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n}\n\nth,\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #f2f2f2;\n}\n\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 34px;\n  height: 20px;\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n.edit-button,\n.delete-button {\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n  margin-right: 5px;\n}\n\n.edit-button:hover {\n  color: #007bff;\n}\n\n.delete-button:hover {\n  color: #dc3545;\n}\n.btn-user-bg {\n  border: none;\n  background: none;\n}\n.btn-user-bg:focus-visible {\n  outline: none;\n}\n.add-new-bg {\n  margin-left: 25px;\n  border: none;\n  background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
