// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1024px) {
  .row {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .row > div {
    width: 100%; /* Full width for inputs on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Groups/Groups.styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB,EAAE,wCAAwC;EAClE;;EAEA;IACE,WAAW,EAAE,6CAA6C;EAC5D;AACF","sourcesContent":["@media (max-width: 1024px) {\n  .row {\n    flex-direction: column; /* Stack vertically on smaller screens */\n  }\n\n  .row > div {\n    width: 100%; /* Full width for inputs on smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
