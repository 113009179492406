/** Initial state  */
export const initialState = {
    loadingMenu: false,
    errorMenu: null,
    loading:false,
    error:false,
    assets: [],
    selectedAsset: null,
    addAsset:null,
    editAsset:null,
    isAddAssetSelected:false,
    isEditAssetSelected: false,
}