import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const HierarchyItem = ({
  asset,
  node,
  onSelect,
  isParentExpanded,
  isSearchEnabled = false,
  isRestricted,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    isParentExpanded || isSearchEnabled
  );

  useEffect(() => {
    setIsExpanded(isParentExpanded || isSearchEnabled);
  }, [isSearchEnabled]);
  const handleExpandCollapse = () => {
    if (!isRestricted) {
      setIsExpanded(!isExpanded);
    }
  };
  const handleNodeSelect = () => {
    if (!isRestricted) {
      onSelect(node);
    }
  };

  return (
    <div style={{ marginLeft: "20px" }}>
      <div>
        <span
          onClick={handleExpandCollapse}
          style={{ cursor: "pointer", fontWeight: "bold" }}
        >
          {node.children.length > 0 &&
            (isExpanded ? (
              <i className="bi bi-caret-down-fill"></i>
            ) : (
              <i className="bi bi-caret-right-fill"></i>
            ))}
        </span>
        <span
          onClick={handleNodeSelect}
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            color: isRestricted ? "lightgrey" : null,
          }}
        >
          {node.thing_name}
        </span>
      </div>
      {isExpanded && node.children.length > 0 && (
        <div>
          {node.children.map((child) => (
            <HierarchyItem
              key={child.thing_id}
              node={child}
              asset={asset}
              onSelect={onSelect}
              isParentExpanded={false}
              isSearchEnabled={isSearchEnabled}
              isRestricted={
                asset != null
                  ? asset?.thingId === child.thing_id ||
                    asset?.thingId === child.parent_id ||
                    isRestricted
                  : false
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HierarchyItem;

HierarchyItem.propTypes = {
  node: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isParentExpanded: PropTypes.bool.isRequired,
  isSearchEnabled: PropTypes.bool.isRequired,
};
