import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import PropTypes from "prop-types";
import { useEffect } from "react";
const UsersList = ({
  users,
  handleAddNewUser,
  handleSearchUser,
  handleEditUser,
  fetchUsers,
}) => {
  const { constants, tableHeaders } = INFRAMARK_CONSTANTS.users;

  useEffect(() => {
    if (users === null) {
      fetchUsers();
    }
  }, []);

  return (
    <div className="admin-panel">
      <div className="header-user">
        <div className="actions" style={{ display: "flex" }}>
          <h4>User Access</h4>
          <button
            className="add-new add-new-bg"
            onClick={() => {
              handleAddNewUser(constants.ADDUSERVIEW);
            }}
          >
            <i className="bi bi-plus-circle"></i> Add User Access
          </button>
        </div>
        <div className="search">
          <button className="search-button btn-user-bg form-control">
            <input
              className="btn-user-bg"
              type="text"
              placeholder="Search User.."
              onChange={(search) => {
                handleSearchUser(search.target.value);
              }}
            />
            <i className="bi bi-search"></i>
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeaders.map((title) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.id}>
              <td>{user.groupName}</td>
              <td>{user.accessTo}</td>
              <td>{user.userId}</td>
              <td>{user.thingName}</td>
              <td className="toggle">
                <label className="switch">
                  <input type="checkbox" disabled checked={user.isActive} />
                  <span className="slider"></span>
                  {""}
                </label>
              </td>
              <td>
                <button
                  className="edit-button"
                  onClick={() => {
                    handleEditUser(user);
                  }}
                >
                  <i aria-label="edituser" className="bi bi-pencil-square"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;

UsersList.propTypes = {
  users: PropTypes.array,
  handleAddNewUser: PropTypes.func.isRequired,
  handleSearchUser: PropTypes.func.isRequired,
  handleEditUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};
