import { useState, useEffect, Suspense, lazy } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import { useCreateUserInitialization } from "./CreateUser.hook";
import BaseComponent from "common/Components/BaseComponent";
import Loading from "common/Components/Loading";
import CustomAlert from "common/Components/CustomAlert";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import Select from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./CreateUser.styles.css";

const ErrorPage = lazy(() => import("common/Components/ErrorPage"));

const CreateUser = () => {
  const { state, actions } = useCreateUserInitialization();
  let roles = INFRAMARK_CONSTANTS.users.dropdown.roles;
  const defaultValues = {
    userId: "",
    email: "",
    enabled: true,
    password: "",
    attributes: {
      groupId: [""],
      role: [""],
    },
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groupNames, setGroupNames] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isUserValidated, setIsUserValidated] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });
  useEffect(() => {
    fetchGroupsListData();
  }, []);
  /** Submit user form data */
  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      setError("password", {
        type: "manual",
        message: "Temporary Password and Confirm password mismatch",
      });
      return;
    }
    delete data["confirmPassword"];
    data["userId"] = data.userId.toLowerCase();
    actions.addNewUser(data, () => {
      setShowSuccess(true);
      // Reset the form to default values
      reset(defaultValues);
    });
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {INFRAMARK_CONSTANTS.users.constants.PASSWORDALERT}
      </Tooltip>
    );
  };

  /**Fetch GroupName If they are not avaialbel */

  const handleDropdownClick = () => {
    if (groupNames.length === 0) {
      fetchGroupsListData();
    }
  };
  const fetchGroupsListData = async () => {
    setLoading(true);
    try {
      const response = await actions.getAllGroups();
      const selectObject = {
        groupId: "",
        groupName: "Select Group",
      };
      const sortedArray = response?.sort((a, b) => a.groupId - b.groupId);
      const groupsArray = [selectObject, ...sortedArray];
      setGroupNames(groupsArray);
    } catch (error) {
      setGroupNames([]);
    } finally {
      setLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === " " || event.keyCode === 32) {
      // Prevent space key press
      event.preventDefault();
    }
  };
  const handleKeyPress = (event) => {
    // Regex to match special characters
    const regex = /[!@#$%^&*()\-+=,.;:'"<>?/\\|`~]/;

    // Prevent special characters
    if (regex.test(event.key)) {
      event.preventDefault();
    }
  };
  const validateUserId = async (value) => {
    if (value?.length <= 0) {
      return;
    }
    try {
      // check if the user ID already exists if true exists else not exists
      const response = await actions.validateUser(value);
      setIsUserValidated(true);
      if (response?.error) {
        setError("userId", {
          type: "manual",
          message: response?.error ?? "Failed to check User ID",
        });
      } else if (response?.data) {
        setError("userId", {
          type: "manual",
          message: "User ID already exist",
        });
      } else {
        clearErrors("userId");
      }
    } catch (error) {
      setIsUserValidated(true);
      setError("userId", {
        type: "manual",
        message: "Failed to check User ID",
      });
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  return (
    <Suspense fallback={<Loading />}>
      <BaseComponent>
        <Tabs
          defaultActiveKey="Createuser"
          id="uncontrolled-tab-example"
          className="ml-2 mt-2"
        >
          <Tab eventKey="Createuser" title="Create User">
            {state.loading && <Loading />}
            {state.error && (
              <Suspense fallback={<Loading />}>
                <ErrorPage
                  errorValue={state.error}
                  handleGoBack={() => {
                    actions.refreshError();
                  }}
                />
              </Suspense>
            )}

            {showSuccess && (
              <CustomAlert
                show={showSuccess}
                title={"Alert"}
                onOkay={() => {
                  setShowSuccess(false);
                }}
                message="User created successfully"
              />
            )}

            {!state.error && !state.loading && (
              <div className="add-user-form">
                <div className="h2-bottom">
                  <h2>Create User</h2>
                </div>
                <form className="col-12 row" onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-12 row">
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor="attributes.groupId.0">Group Name</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <Controller
                          name="attributes.groupId.0"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <select
                              id="attributes.groupId.0"
                              aria-label="Group Name"
                              className="form-select"
                              value={field.value || ""}
                              onChange={(e) =>
                                setValue("attributes.groupId.0", e.target.value)
                              }
                              style={{
                                borderColor: errors?.groupId ? "red" : "",
                              }}
                              {...field}
                              onClick={handleDropdownClick}
                            >
                              {loading ? (
                                <option>Loading...</option>
                              ) : (
                                groupNames.map((type) => (
                                  <option
                                    key={type.groupId}
                                    value={type.groupId}
                                  >
                                    {type.groupName}
                                  </option>
                                ))
                              )}
                            </select>
                          )}
                        />
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor={"userId"}>User Id</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <input
                          className={`form-control`}
                          id={"userId"}
                          style={{
                            borderColor: errors?.userId ? "red" : "",
                          }}
                          autoComplete="off"
                          autoCorrect="off"
                          type="text"
                          {...register("userId", {
                            required: true,
                            onBlur: (e) => validateUserId(e.target.value),
                            onChange: (e) => setIsUserValidated(false),
                          })}
                          maxLength={100}
                          onKeyDown={(event) => {
                            handleKeyDown(event);
                            handleKeyPress(event);
                          }}
                        />
                        {errors?.userId && <p>{errors?.userId?.message}</p>}
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor="password">Temporary Password</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <div className="input-group">
                            <input
                              className={`form-control`}
                              id={"password"}
                              type={showPassword ? "text" : "password"}
                              style={{
                                borderColor: errors?.password ? "red" : "",
                              }}
                              {...register("password", {
                                required: true,
                              })}
                              maxLength={100}
                            />

                            <span
                              className="input-group-text"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            >
                              {showPassword ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </span>
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor="cpassword">Confirm Password</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <div className="input-group">
                            <input
                              className={`form-control`}
                              id={"cpassword"}
                              type={showCPassword ? "text" : "password"}
                              style={{
                                borderColor: errors?.password ? "red" : "",
                              }}
                              {...register("confirmPassword", {
                                required: true,
                              })}
                              maxLength={100}
                            />

                            <span
                              className="input-group-text"
                              onClick={() => setShowCPassword(!showCPassword)}
                              style={{ cursor: "pointer" }}
                            >
                              {showCPassword ? (
                                <i className="bi bi-eye-fill"></i>
                              ) : (
                                <i className="bi bi-eye-slash-fill"></i>
                              )}
                            </span>
                          </div>
                         
                        </OverlayTrigger>
                        {errors?.password && (
                            <span style={{color:'red'}}>{errors?.password?.message}</span>
                          )}
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <input
                          className={`form-control`}
                          id={"email"}
                          style={{
                            borderColor: errors?.email ? "red" : "",
                          }}
                          type="email"
                          {...register("email", {
                            required: true,
                          })}
                          autoComplete="off"
                          autoCorrect="off"
                        />
                      </div>
                      <div className="col-6"></div>
                    </div>
                    <div className=" form-group col-sm-12 row">
                      <div className="col-2">
                        <label htmlFor={"attributes.role.0"}>Role</label>
                      </div>
                      <div className="col-1">:</div>
                      <div className="col-3">
                        <Controller
                          control={control}
                          name="attributes.role.0"
                          rules={{ required: true }}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              id="attributes.role.0"
                              aria-label="Role"
                              inputRef={ref}
                              options={roles}
                              value={
                                roles.find(
                                  (option) => option.value === value
                                ) || null
                              } // Set the value correctly
                              onChange={(selectedOption) => {
                                onChange(
                                  selectedOption ? selectedOption.value : null
                                ); // Store only the value
                              }}
                              isClearable
                            />
                          )}
                        />
                      </div>
                      <div className="col-6"></div>
                    </div>

                    <div className="col-12 row">
                      <div className="col-2"></div>
                      <div className="col-4" style={{ textAlign: "end" }}>
                        <button
                          type="button"
                          className="btn btn-light btn-cncl"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={
                            Object.keys(errors).length > 0 || !isUserValidated
                          }
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Tab>
        </Tabs>
      </BaseComponent>
    </Suspense>
  );
};

export default CreateUser;
