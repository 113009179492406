import { useEffect } from "react";
import "./AlertConfigurations.styles.css";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import PropTypes from "prop-types";

const AlertConfigurations = ({
  alerts,
  parentName,
  fetchAlertConfigurations,
  isTabSelected,
  handleAddConfiguration,
  handlePreviewConfiguration,
  handleEditConfiguration,
}) => {
  const alertTableHeaders =
    INFRAMARK_CONSTANTS.alerts.tableHeaders.configuration;
  useEffect(() => {
    if (!alerts && isTabSelected) {
      fetchAlertConfigurations();
    }
  }, [isTabSelected, alerts]);
  return (
    <>
      <div className="configuration-header bottom-div">
        <div className="conf-h2">Name: {parentName}</div>

        <button
          className="btn-without-bg-header-confg"
          onClick={handleAddConfiguration}
        >
          <i className="bi bi-plus"></i>Add
        </button>
      </div>

      <div className="table-container properties">
        <table>
          <thead>
            <tr>
              {alertTableHeaders.map((title) => (
                <th key={title}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {alerts?.length > 0 &&
              alerts.map((alert) => (
                <tr key={alert.alertConfigId}>
                  <td>{alert.alertConfigName}</td>
                  <td>{alert.parentId}</td>
                  <td>{alert.tagsAssociated}</td>
                  <td>{alert.isPostProcessing ? "TRUE" : "FALSE"}</td>
                  <td>
                    {alert.postProcessingSchedule
                      ? parseInt(alert.postProcessingSchedule) / 60
                      : "--"}
                  </td>
                  <td>
                    <button
                      aria-label="Edit configuration"
                      className="action-btn btn-without-bg-header"
                      onClick={() => {
                        handleEditConfiguration(alert);
                      }}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    <button
                      aria-label="Preview configuration"
                      className="action-btn btn-without-bg-header"
                      onClick={() => {
                        handlePreviewConfiguration(alert);
                      }}
                    >
                      <i className="bi bi-eye"></i>
                    </button>
                  </td>
                </tr>
              ))}
            {alerts?.length <= 0 && (
              <tr>
                <td colSpan={6} className="td-alert-empty-row">
                  <h5>Alerts not available</h5>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AlertConfigurations;

AlertConfigurations.propTypes = {
  alerts: PropTypes.array,
  parentName: PropTypes.string.isRequired,
  fetchAlertConfigurations: PropTypes.func.isRequired,
  isTabSelected: PropTypes.bool.isRequired,
  handleAddConfiguration: PropTypes.func.isRequired,
  handlePreviewConfiguration: PropTypes.func.isRequired,
  handleEditConfiguration: PropTypes.func.isRequired,
};
