import { useContext, useEffect, Suspense } from "react";
import BaseComponent from "common/Components/BaseComponent";
import "./Home.styles.css";
import AppContext from "common/Context/AppContext";
import UserContext from "common/Context/UserContext";
import { Link } from "react-router-dom";
import Backgroundimage from "../../assets/inframarkbg.webp";
import Loading from "common/Components/Loading";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

export default function Home() {
  const { updateNavigationItem } = useContext(AppContext);
  const { userProfile } = useContext(UserContext);
  const accessPages = userProfile.profile.accessTo.map((pageName) =>
    pageName.toLowerCase()
  );
  const pageRoutes = parseInt(userProfile.profile.groupId) === 0 ?  INFRAMARK_CONSTANTS.routes.superAdminRoutes : INFRAMARK_CONSTANTS.routes.userAdminRoutes;
  useEffect(() => {
    updateNavigationItem("Home");
  }, [])
  return (
    <BaseComponent>
      <Suspense fallback={<Loading />}>
        <main className="main">
          <img
            src={Backgroundimage}
            alt="Water Plant"
            className="background-image"
          />
          <div className="button-container">
            {pageRoutes.map((page) => {
              // Check if the item's label is in the labelsToMatch array
              if (
                accessPages.includes("all") ||
                accessPages.includes(page.value.toLowerCase()) ||
                accessPages.length === 0
              ) {
                // Render a Link tag for matching labels
                return page.value.toLowerCase() === "all" ? null : (
                  <Link to={`${page.route}`} key={page.value}>
                    <button className="button">{page.label}</button>
                  </Link>
                );
              }
              return null; // Don't render anything if there's no match
            })}
          </div>
        </main>
      </Suspense>
    </BaseComponent>
  );
}
