import { BrowserRouter} from "react-router-dom";
import { useContext } from "react";
import HomeRoutes from "./HomeRoutes";
import LoginRoutes from "./LoginRoutes";
import UserContext from "common/Context/UserContext";
const RootRouter = () => {
  const { userProfile } = useContext(UserContext);
  
  return (
    <BrowserRouter>
      {userProfile.isLoggedin ? <HomeRoutes /> : <LoginRoutes />}
    </BrowserRouter>
  );
};

export default RootRouter;

/**
Conditions for logged in users:

 1) If user has multiple page permissions we will take him to the home page
 2) If user has single page permission we will directly take him to the permitted page
 */
