import { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import AppContextProvider from "common/Context/AppContextProvider";
import UserContextProvider from "common/Context/UserContextProvider";
import { initKeycloak, keycloak } from "common/HttpClient";
import Loading from "common/Components/Loading";
const root = ReactDOM.createRoot(document.getElementById("root"));
const Root = () => {
  const [isAuthencating, setIsAuthencating] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  useEffect(() => {
    initKeycloak((profile) => {
      const parsedProflie = {
        userid: profile.username,
        name: profile.username,
        groupId: profile.attributes.groupId[0],
        id: profile.id,
        role: profile.attributes.role[0],
        token:keycloak.token
      };
      setUserProfile(parsedProflie);
      setIsAuthencating(false);
    });
  }, [initKeycloak]);
  if (isAuthencating) {
    return (
      <div>
        <Loading loadingText="Authenticating...." />
      </div>
    );
  }
  return (
    <UserContextProvider>
      <AppContextProvider>
        <App profile={userProfile} />
      </AppContextProvider>
    </UserContextProvider>
  );
};
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
