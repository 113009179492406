/** API Calls */
import {
  getRequest,
  postRequest,
  putRequest,
  getAllRequests,
} from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

export const AssetViewServices = () => {
  const service_paths = INFRAMARK_CONSTANTS.assets.services;
  const services = {
    /** API Call to get all assets */
    getAllThings: async () => {
      const response = await getRequest(service_paths.assets);
      return response;
    },
    /** API to get asset hierarchy */
    getAssetHierarchy: async () => {
      const response = await getRequest(service_paths.assetHierarchy);
      return response;
    },
    /** API Call to get asset details */
    getThingDetails: async (thing_id) => {
      const response = await getRequest(
        `${service_paths.assetDetails}${thing_id}`
      );
      return response;
    },
    /** API Call to get Thingtype details by using ThingType Id */
    getThingTypeByID: async (thing_type_id) => {
      const response = await getRequest(
        `${service_paths.assetTypeDetails}${thing_type_id}`
      );
      return response;
    },
    /** API Call to get Thingtype category details by using ThingType Id */
    getThingTypeCategoryByID: async (thing_type_category_id) => {
      const response = await getRequest(
        `${service_paths.assetTypeCategoryDetails}${thing_type_category_id}`
      );
      return response;
    },
    getTypeInfoAndCategoryInfo: async (
      thing_type_id,
      thing_type_category_id
    ) => {
      let urls = [`${service_paths.assetTypeDetails}${thing_type_id}`];
      if (thing_type_category_id) {
        urls.push(`${service_paths.assetTypeCategoryDetails}${thing_type_category_id ?? 0}`)
      }
      const responses = await getAllRequests(urls);
      return responses;
    },
    /** API Call to get all Thing Types */
    getAllThingTypes: async () => {
      const response = await getRequest(service_paths.assetTypes);
      return response;
    },

    /** API Call to get all Thing Type categories */
    getAllThingTypeCategories: async (thing_type_id) => {
      const response = await getRequest(
        `${service_paths.assetTypeCategories}${thing_type_id}`
      );
      return response;
    },
    /** API Call to get image using image id */
    getThingImageByID: async (thing_image_id) => {
      const response = await getRequest(
        `${service_paths.assetImage}${thing_image_id}`
      );
      return response;
    },
    /** API Call to get all images using ThingType Id */
    getAllThingImagesByTypeId: async (thing_type_id) => {
      const response = await getRequest(
        `${service_paths.assetTypeImages}${thing_type_id}`
      );
      return response;
    },
    /** API Call to create new asset and asset properties */
    createNewAsset: async (asset) => {
      const response = await postRequest(service_paths.createAsset, asset);
      return response;
    },
    /** API Call to update asset details and properties */
    updateAsset: async (asset) => {
      const response = await putRequest(
        `${service_paths.updateAsset}${asset.thing.thingId}`,
        asset
      );
      return response;
    },
  };
  return services;
};
