/** Actions */
export const ASSETVIEW_ACTIONS = {
    LOADING_MENU : "loading_menu",
    ERROR_MENU: "error_menu",
    LOADING : "loading",
    ERROR: "error",
    ASSETS: "assets",
    ASSETSELECTED:'asset_selected',
    ADDASSET:'add_asset',
    EDITASSET:'edit_asset'
}