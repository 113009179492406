import { useEffect, useState } from "react";
import placeholderImage from "../../../../../assets/place_holder.svg";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import "./ThingTypeImage.style.css";
import PropTypes from "prop-types";
import Loading from "common/Components/Loading";
import AddNewImage from "./Components/AddNewImage";
import CustomAlert from "common/Components/CustomAlert";
import UpdateOrPreviewImage from "./Components/UpdateOrPreviewImage";

const ThingTypeImage = ({
  constants,
  selectedView,
  selectedThingType,
  control,
  fetchAllImages,
  addNewThingTypeImage,
  updateThingTypeImage,
}) => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const [images, setImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const showNewImage =
    (selectedView === constants.ADDTHINGTYPEVIEW &&
      (images?.data?.length === 0 || images === null)) ||
    selectedView === constants.EDITTHINGTYPEVIEW;

  const uploadImage = async (data) => {
    try {
      const response = await addNewThingTypeImage(data);
      if (response?.data) {
        setImages({ data: [...images.data, response?.data], error: "" });
      } else {
        setShowAlert(true);
        setAlertInfo({
          title: "Upload Image Error",
          message: "Failed to upload image. Try again!",
        });
      }
    } catch (error) {
      setShowAlert(true);
      setAlertInfo({
        title: "Upload Image Error",
        message: "Failed to upload image. Try again!",
      });
    }
  };

  const handleClose = (data) => {
    if (data) {
      data["thingTypeId"] = selectedThingType?.thingTypeId ?? "";
      if (selectedView === constants.ADDTHINGTYPEVIEW) {
        setImages({ data: [data], error: "" });
        addNewThingTypeImage(data);
      } else {
        uploadImage(data);
      }
    }
    setShow(false);
  };

  const handleClosePeview = (data) => {
    if (data && selectedView === constants.EDITTHINGTYPEVIEW) {
      updateImage(data);
    } else {
      setSelectedImage(null);
      setShowModalPreview(false);
    }
  };
  const updateImage = async (imageData) => {
    try {
      const response = await updateThingTypeImage(imageData);
      if (response?.data) {
        const index = images.data.findIndex((image)=>image.thingImageId === imageData.thingImageId)
        let allImages = [...images.data]
        allImages[index] = response?.data
        setImages({ data: allImages, error: "" });
      } else {
        setShowAlert(true);
        setAlertInfo({
          title: "Update Image Error",
          message: "Failed to update image. Try again!",
        });
      }
    } catch (error) {
      setShowAlert(true);
      setAlertInfo({
        title: "Update Image Error",
        message: "Failed to update image. Try again!",
      });
    } finally {
      setSelectedImage(null);
      setShowModalPreview(false);
    }
  };
  useEffect(() => {
    if (selectedThingType != null && images == null) {
      loadImages();
    }
  }, [selectedThingType, images]);

  const loadImages = async () => {
    setLoading(true);
    try {
      const response = await fetchAllImages(selectedThingType.thingTypeId);
      if (response.error) {
        setImages({ data: [], error: response.error });
      } else {
        setImages({ data: response?.data ?? [], error: "" });
      }
    } catch (error) {
      setImages({ data: [], error: error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col-12 row" style={{ padding: "0" }}>
      <Container style={{ padding: "0" }}>
        {loading && <Loading loadingText="Fetching images..." />}
        {images?.error ? (
          <h5>{images?.error}</h5>
        ) : (
          <Row>
            {images?.data.length > 0 &&
              images.data.map((image) => (
                <Col
                  key={image.thingImageId ?? image.thingImageName}
                  lg={1}
                  className="mt-2"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    if (selectedView !== constants.ADDTHINGTYPEVIEW) {
                      setSelectedImage(image);
                      setShowModalPreview(true);
                    }
                  }}
                >
                  {image?.thingImage && (
                    <img
                      className="img-thumbnail d-block"
                      id="thumbnail-image"
                      src={
                        image?.thingImage
                          ? `data:image/png;base64,${image?.thingImage}`
                          : placeholderImage
                      }
                      alt="ThingType Images"
                      style={{ cursor: "pointer", width: 150 }}
                    />
                  )}
                  {image?.image && (
                    <img
                      className="img-thumbnail d-block"
                      id="thumbnail-image"
                      src={
                        URL.createObjectURL(image?.image) ?? placeholderImage
                      }
                      alt="ThingType Images"
                      style={{ cursor: "pointer", width: 150 }}
                    />
                  )}
                  <label
                    className="asset-image-title image-title-display"
                    htmlFor="thumbnail-image"
                  >
                    {image?.thingImageName ?? ""}
                  </label>
                </Col>
              ))}
            {showNewImage && (
              <Col
                lg={1}
                className="mt-2"
                onClick={() => {
                  setShow(true);
                }}
              >
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    border: "1px solid green", // Add a green border
                    borderRadius: "5px", // Optional: rounded corners
                    padding: "10px", // Padding for spacing
                    textAlign: "center", // Center text alignment
                    color: "green",
                  }}
                >
                  <i className="bi bi-plus h3"></i>
                  <span>Add New Image</span>
                </div>
              </Col>
            )}
            {images?.data?.length <= 0 &&
              selectedThingType != null &&
              selectedView === constants.THINGTYPEPREVIEW && (
                <>
                  <h5 className="h5-no-img">No Images Found</h5>
                  <hr />
                </>
              )}
          </Row>
        )}
      </Container>

      {/* Add image popup open */}
      {show && (
        <AddNewImage
          show={show}
          handleClose={handleClose}
          control={control}
          isNewThingType={selectedView === constants.ADDTHINGTYPEVIEW}
        />
      )}
      {/*Add image popup close */}
      {/*Image Preview Popup open */}
      {showModalPreview && (
        <UpdateOrPreviewImage
          show={showModalPreview}
          handleCloseAction={handleClosePeview}
          imageData={selectedImage}
          isPreview={selectedView !== constants.EDITTHINGTYPEVIEW}
        />
      )}
      {/*Image Preview Popup close */}
      {showAlert && (
        <CustomAlert
          show={showAlert}
          title={alertInfo.title}
          message={alertInfo.message}
          onOkay={() => {
            setShowAlert(false);
            setAlertInfo(null);
          }}
        />
      )}
    </div>
  );
};

export default ThingTypeImage;

ThingTypeImage.propTypes = {
  selectedView: PropTypes.string,
  constants: PropTypes.object,
  selectedThingType: PropTypes.object,
  fetchAllImages: PropTypes.func.isRequired,
  addNewThingTypeImage: PropTypes.func.isRequired,
  control: PropTypes.any,
};
