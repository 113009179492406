const INITIAL_USER_STATE = {
  token: "",
  profile: null,
  isLoggedin: false,
};
const APP_INFO = {
  navigation_items: [
    { title: "Home", navPath: "/", isActive: false },
  ],
};
export { INITIAL_USER_STATE, APP_INFO };
