import { getRequest, postRequest, putRequest } from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
export const AlertViewServices = () => {
  const service_paths = INFRAMARK_CONSTANTS.alerts.services;
  const services = {
    getAlertConfigurations: async (thing_id) => {
      const response = await getRequest(`${service_paths.alertConfigurations}${thing_id}`);
      return response;
    },
    // alertConfigurationDetails
    getAlertConfigurationDetails: async (configuration_id) => {
      const response = await getRequest(`${service_paths.alertConfigurationDetails}${configuration_id}`);
      return response;
    },
    createNewAlertConfiguration: async (congiration_data) => {
      const response = await postRequest(service_paths.createAlertConfiguration, congiration_data);
      return response;
    },
    updateAlertConfiguration: async (congiration_data) => {
      const response = await putRequest(`${service_paths.updateAlertConfiguration}${congiration_data.alertConfigId}`, congiration_data);
      return response;
    },

    getAlertConditionDetails: async (condition_id) => {
      const response = await getRequest(`${service_paths.alertConditionDetails}${condition_id}`);
      return response;
    },
    createAlertCondition: async (condition_data) => {
      const response = await postRequest(service_paths.createAlertCondition, condition_data);
      return response;
    },
    updateCondition: async (condition_data) => {
      const response = await putRequest(`${service_paths.updateAlertCondition}${condition_data.alertConditionId}`, condition_data);
      return response;
    },
  };
  return services;
};
