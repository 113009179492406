/** Group reducer actions */
export const GROUPS_ACTIONS = {
    LOADING : "loading",
    ERROR: "error",
    GROUPSLIST: "group_list",
    ADDGROUP:'add_group',
    EDITGROUP:'edit_group',
    SELECTEDGROUP:'selected_group',
    SELECTEDVIEW:'selected_view',
    SEARCHGROUP:'search_group'
}