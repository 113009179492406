import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "common/Context/UserContext";

const PageNotFound = ({
  statusCode = "4",
  errorText = "The page you are looking for was not found.",
  showLogout = false,
}) => {
  const navigate = useNavigate();
  const { logout } = useContext(UserContext);
  return (
    <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                <span className="display-1 fw-bold">4</span>
                <span className="display-1 fw-bold">0</span>
                <span className="display-1 fw-bold bsb-flip-h">
                  {statusCode}
                </span>
              </h2>
              <h3 className="h2 mb-2">Oops! You're lost.</h3>
              <p className="mb-5">{errorText}</p>

              <input
                className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0"
                type="button"
                onClick={() => {
                  navigate("/");
                  if (showLogout) {
                    logout();
                  }
                }}
                value={showLogout ? "Logout" : "Back to Home"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
