import { THINGTYPE_ACTIONS } from "./ThingType.actions";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

export const ThingtypeReducer = (state, action) => {
  switch (action.type) {
    case THINGTYPE_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case THINGTYPE_ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case THINGTYPE_ACTIONS.THINGTYPES:
      return {
        ...state,
        thingTypes: action.payload,
        isAddThingTypeSelected: false,
        isEditThingTypeSelected: false,
      };
    case THINGTYPE_ACTIONS.SELECTEDVIEW:
      return {
        ...state,
        selectedView: action.payload,
        isAddThingTypeSelected:
          action.payload ===
          INFRAMARK_CONSTANTS.thingTypeDetails.constants.ADDTHINGTYPEVIEW,
        isEditThingTypeSelected:
          action.payload ===
          INFRAMARK_CONSTANTS.thingTypeDetails.constants.EDITTHINGTYPEVIEW,
        isPreviewThingTypeSelected:
          action.payload ===
          INFRAMARK_CONSTANTS.thingTypeDetails.constants.THINGTYPEPREVIEW,
      };
    case THINGTYPE_ACTIONS.EDITTHINGTYPE:
      return {
        ...state,
        selectedView: INFRAMARK_CONSTANTS.thingTypeDetails.constants.EDITTHINGTYPEVIEW,
        isEditThingTypeSelected: true,
        selectedThingType: action.payload,
      };
      case THINGTYPE_ACTIONS.THINGTYPEPREVIEW:
      return {
        ...state,
        selectedView: INFRAMARK_CONSTANTS.thingTypeDetails.constants.THINGTYPEPREVIEW,
        selectedThingType: action.payload,
      };
    case THINGTYPE_ACTIONS.SELECTEDTHINGTYPE:
      return {
        ...state,
        selectedThingType: action.payload,
      };
    default:
      return { ...state };
  }
};
