import { useReducer } from "react";
import { initialState } from "./AlertView.model";
import { AlertViewReducer } from "./AlertView.reducers";
import { AlertViewServices } from "./AlertView.services";
import { AssetViewServices } from "pages/AssetView/AssetView.services";
import { ALERTVIEW_ACTIONS } from "./AlertView.actions";

export const useAlertViewInitialization = () => {
  const [state, dispatch] = useReducer(AlertViewReducer, initialState);
  const assetSearvices = AssetViewServices();
  const services = AlertViewServices();
  services.getAllThingImagesByTypeId = assetSearvices.getAllThingImagesByTypeId;
  services.getThingImageByID = assetSearvices.getThingImageByID;
  const actions = AlertViewActions(dispatch, services);
  return { state, actions };
};

const AlertViewActions = (dispatch, services) => {
  const actions = {
    /** API Call: Get all alert configurations based on thing id information */
    getAllAlertConfigurations: async (thing_id) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAlertConfigurations(thing_id);
      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.ALERTS,
          payload: response?.data ?? [],
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    getAlertConfigurationDetails: async (alertConfigId) => {
      // getAlertConfigurationDetails
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAlertConfigurationDetails(alertConfigId);
      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.SELECTEDALERT,
          payload: response?.data ?? {},
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    //createNewAlertConfiguration
    createAlertConfiguration: async (configuration_data, status) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.createNewAlertConfiguration(configuration_data);

      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.SELECTEDALERT,
          payload: response?.data ?? {},
        });
        dispatch({
          type: ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS,
          payload: status,
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    //updateAlertConfiguration
    updateAlertConfiguration: async (configuration_data, status) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.updateAlertConfiguration(configuration_data);

      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.SELECTEDALERT,
          payload: response?.data ?? {},
        });
        dispatch({
          type: ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS,
          payload: status,
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    fetchAllThingImages: async (thing_type_id) => {
      const response = await services.getAllThingImagesByTypeId(thing_type_id);
      return response;
    },

    //getAlertConditionDetails

    getAlertConditionDetails: async (condition_id) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAlertConditionDetails(condition_id);
      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.SELECTEDCONDITION,
          payload: response?.data ?? [],
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },

    createnewAlertCondition: async (condition_data, status) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.createAlertCondition(condition_data);

      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS,
          payload: status,
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    // updateAlertCondition
    updateAlertCondition: async (condition_data, status) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.updateCondition(condition_data);

      if (response?.error) {
        dispatch({
          type: ALERTVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS,
          payload: status,
        });
      }
      dispatch({
        type: ALERTVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },

    changeViewStatus: (status) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS,
        payload: status,
      });
    },
    setSelectedCategory: (selected_category) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.SELECTEDCATEGORY,
        payload: selected_category,
      });
    },
    setSelectedAlertConfiguration: (alert) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.SELECTEDALERT,
        payload: alert,
      });
    },
    setSelectedAlertCondition: (condition) => {
      dispatch({
        type: ALERTVIEW_ACTIONS.SELECTEDCONDITION,
        payload: condition,
      });
    },

    fetchIconImageById: async (icon_image_id) => {
      const response = await services.getThingImageByID(icon_image_id);
      return response?.data ?? null;
    },
    refreshError: () => {
      dispatch({
        type: ALERTVIEW_ACTIONS.ERROR,
        payload: null,
      });
    },
  };
  return actions;
};
