// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonnts.com-MrEavesXLModOT-Reg.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonnts.com-MrEavesXLModNarOT-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'InframarkFont';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InframarkFont-bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: bold;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/Fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,+DAAkE;EAClE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,+DAAsE;EACtE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: 'InframarkFont';\n  src: url('./fonnts.com-MrEavesXLModOT-Reg.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'InframarkFont-bold';\n  src: url('./fonnts.com-MrEavesXLModNarOT-Bold.ttf') format('truetype');\n  font-weight: bold;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
