import { useState, useEffect } from "react";
import HierarchyItem from "./HierarchyItem";
import PropTypes from "prop-types";
import { searchHierarchy } from "common/Utilities";
const HierarchyView = ({ hierarchyData, handleOnSelect, asset = null }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(hierarchyData ?? []);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    if (searchQuery.length === 0) {
      setData(hierarchyData);
    } else {
      const filteredHeirarchy = searchHierarchy(hierarchyData, searchQuery);
      setData(filteredHeirarchy ? filteredHeirarchy : []);
    }
  }, [searchQuery, hierarchyData]);

  const expandAssetTree = (targetId, assets) => {
    let found = false;
    const expandedAssets = assets.map((node) => {
      if (node.thing_id === targetId) {
        found = true;
        return { ...node, isExpanded: true };
      } else if (node.children && node.children.length > 0) {
        const result = expandAssetTree(targetId, node.children);
        if (result.found) {
          found = true;
          return { ...node, isExpanded: true, children: result.expandedAssets };
        }
      }
      return { ...node };
    });
    return { expandedAssets, found };
  };
  return (
    <div>
      {/* {asset === null && <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: "10px", padding: "5px" }}
      />} */}
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: "10px", padding: "5px" }}
      />
      <div>
        {data?.map((node) => (
          <HierarchyItem
            asset={asset}
            key={node.thing_id}
            node={node}
            onSelect={handleOnSelect}
            isParentExpanded={true}
            isSearchEnabled={searchQuery.length > 0}
            isRestricted={
              asset != null
                ? asset?.thingId === node.thing_id ||
                  asset?.thingId === node.parent_id
                : false
            }
          />
        ))}
      </div>
    </div>
  );
};

export default HierarchyView;
HierarchyView.propTypes = {
  hierarchyData: PropTypes.array,
  handleOnSelect: PropTypes.func.isRequired,
};
