/** Reducer updates */
import { ASSETVIEW_ACTIONS } from "./AssetView.actions";

export const AssetViewReducer = (state, action) => {
  
  switch (action.type) {
    case ASSETVIEW_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ASSETVIEW_ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ASSETVIEW_ACTIONS.LOADING_MENU:
      return {
        ...state,
        loadingMenu: action.payload,
      };
    case ASSETVIEW_ACTIONS.ERROR_MENU:
      return {
        ...state,
        errorMenu: action.payload,
      };
    case ASSETVIEW_ACTIONS.ASSETS:
      return {
        ...state,
        assets: action.payload,
        errorMenu:null,
        error:null
      };
    case ASSETVIEW_ACTIONS.ASSETSELECTED:
      return {
        ...state,
        selectedAsset: action.payload,
        addAsset: null,
        editAsset: null,
        error:null,
        isAddAssetSelected:false,
        isEditAssetSelected:false,
      };
    case ASSETVIEW_ACTIONS.ADDASSET:
      return {
        ...state,
        addAsset: action.payload,
        isAddAssetSelected:true,
        isEditAssetSelected:false,
        editAsset: null,
      };
    case ASSETVIEW_ACTIONS.EDITASSET:
      return {
        ...state,
        editAsset: action.payload,
        addAsset: null,
        isAddAssetSelected:false,
        isEditAssetSelected:true,
      };
    default:
      return { ...state };
  }
};
