// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    height: calc(100vh - 45px);
    background-color: aliceblue;
}
.search-menu {
    border-right: 1px solid;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
}
.menu-list {
    border-top: 1px solid;
}

.background-image {
  width: 100%;
  height: 100vh;
  display: block;
}

.button-container {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 10px;
    font-weight: 500;
  font-size: 18px;
 color: var(--txt-infra-color);
  background-color: rgba(255, 255, 255,);
  border-bottom: #B0D136 10px solid;
  border-right: none;
  border-top: none;
  border-left: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 20vh;
  width: 10rem;
  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;
}

.button:hover {
background: transparent linear-gradient(180deg, #008FD5 0%, #B0D136 100%) 0% 0% no-repeat padding-box;
  color: var(--txt-white-color);
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;AACA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,qBAAqB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;IAChB,gBAAgB;EAClB,eAAe;CAChB,6BAA6B;EAC5B,sCAAsC;EACtC,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,qEAAqE;AACvE;;AAEA;AACA,qGAAqG;EACnG,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".home-container {\n    height: calc(100vh - 45px);\n    background-color: aliceblue;\n}\n.search-menu {\n    border-right: 1px solid;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    padding: 0.5rem;\n}\n.menu-list {\n    border-top: 1px solid;\n}\n\n.background-image {\n  width: 100%;\n  height: 100vh;\n  display: block;\n}\n\n.button-container {\n  position: absolute;\n  top: 65%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  gap: 10px;\n}\n\n.button {\n  padding: 10px 10px;\n    font-weight: 500;\n  font-size: 18px;\n color: var(--txt-infra-color);\n  background-color: rgba(255, 255, 255,);\n  border-bottom: #B0D136 10px solid;\n  border-right: none;\n  border-top: none;\n  border-left: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n  height: 20vh;\n  width: 10rem;\n  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;\n}\n\n.button:hover {\nbackground: transparent linear-gradient(180deg, #008FD5 0%, #B0D136 100%) 0% 0% no-repeat padding-box;\n  color: var(--txt-white-color);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
