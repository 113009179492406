import { Modal, Button } from "react-bootstrap";
import { lazy, useEffect } from "react";
import PropTypes from "prop-types";
import '../AddEditUser.styles.css';
import HierarchyView from "./HierarchyView";
const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));

const HierarchyModel = ({
  title = "Select an Asset",
  showHierarchy,
  handleShowHierarchy,
  getHierarchyList = () => {},
  hierarchylist,
  handleSelectHierarchy,
  asset = null
}) => {
  useEffect(() => {
    if (hierarchylist === null) {
      getHierarchyList();
    }
  }, [hierarchylist]);

  return (
    <Modal show={showHierarchy} onHide={() => handleShowHierarchy(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="model-popup-height">
        <HierarchyView
          hierarchyData={hierarchylist}
          handleOnSelect={(value) => {
            handleSelectHierarchy(value);
          }}
          asset = {asset}
        />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default HierarchyModel;

HierarchyModel.propTypes = {
  hierarchylist: PropTypes.array,
  showHierarchy: PropTypes.bool.isRequired,
  handleShowHierarchy: PropTypes.func.isRequired,
  getHierarchyList: PropTypes.func.isRequired,
  handleSelectHierarchy: PropTypes.func.isRequired,
};
