import { USERS_ACTIONS } from "./Users.actions";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
export const UsersReducer = (state, action) => {
  switch (action.type) {
    case USERS_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case USERS_ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case USERS_ACTIONS.USERS:
      return {
        ...state,
        users: action.payload,
        usersList: action.payload,
        isAddUserSelected: false,
        isEditUserSelected: false,
        error:null
      };
    case USERS_ACTIONS.SEARCHUSER: {
      const searchString = action.payload.toLowerCase();
      const filteredusers = state.users.filter((user) => {
        return (
          user.groupName.toLowerCase().includes(searchString) ||
          user.accessTo.toLowerCase().includes(searchString) ||
          user.userId.toLowerCase().includes(searchString) ||
          user.thingName.toLowerCase().includes(searchString)
        );
      });
      return {
        ...state,
        usersList: filteredusers.length > 0 ? filteredusers : [],
      };
    }
    case USERS_ACTIONS.SELECTEDVIEW:
      return {
        ...state,
        selectedView: action.payload,
        isAddUserSelected:
          action.payload === INFRAMARK_CONSTANTS.users.constants.ADDUSERVIEW,
        isEditUserSelected:
          action.payload === INFRAMARK_CONSTANTS.users.constants.EDITUSERVIEW,
      };
    case USERS_ACTIONS.EDITUSER:
      return {
        ...state,
        selectedView: INFRAMARK_CONSTANTS.users.constants.EDITUSERVIEW,
        isEditUserSelected: true,
        selectedUser: action.payload,
      };
    case USERS_ACTIONS.SELECTEDUSER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case USERS_ACTIONS.HIERARCHYLIST:
      return {
        ...state,
        hierarchylist: action.payload,
      };
    case USERS_ACTIONS.LOADINGHIERARCHY:
      return {
        ...state,
        loadingHierarchy: action.payload,
      };
    case USERS_ACTIONS.ADDUPDATEUSER:{
      let newArray = [...state.users]
      const index = state.users.findIndex(item => item.id === action.payload.user.id);
      if (index !== -1) {
        // Replace existing object
        newArray[index] = action.payload.user;
      } else {
        // Add new object
        newArray.push(action.payload.user);
      }
      return {
        ...state,
        selectedView: action.payload.view,
        users: newArray,
        usersList: newArray,
        isAddUserSelected: false,
        isEditUserSelected: false,
      }}
    default:
      return { ...state };
  }
};
