import Header from "../Header/Header";
import PropTypes from "prop-types";
import './BaseComponent.styles.css'
export default function BaseComponent({ children }) {
  return (
    <>
      <Header />
      <div className="base-styles-body">{children}</div>
    </>
  );
}

BaseComponent.propTypes = {
  children: PropTypes.any
};
