/** Initial state  */
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants"
export const initialState = {
    loading:false,
    error:false,
    thingTypes: null,
    selectedThingType: null,
    isAddThingTypeSelected:false,
    isEditThingTypeSelected: false,
    isPreviewThingTypeSelected: false,
    selectedView:INFRAMARK_CONSTANTS.thingTypeDetails.constants.THINGTYPELISTVIEW, //default list mode
}