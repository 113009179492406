import { GROUPS_ACTIONS } from "./Groups.actions";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

export const GroupsReducer = (state, action) => {
  switch (action.type) {
    case GROUPS_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GROUPS_ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GROUPS_ACTIONS.GROUPSLIST:
      return {
        ...state,
        groupsList: action.payload,
        groups: action.payload,
        error: null,
      };
    case GROUPS_ACTIONS.SELECTEDVIEW:
      return {
        ...state,
        selectedView: action.payload.status,
        selectedGroup: action.payload.group,
      };
    case GROUPS_ACTIONS.EDITUSER:
      return {
        ...state,
        selectedView: INFRAMARK_CONSTANTS.groups.constants.GROUPEDITEVIEW,
        selectedGroup: action.payload,
      };
    case GROUPS_ACTIONS.SELECTEDGROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    case GROUPS_ACTIONS.SEARCHGROUP: {
      const searchString = action.payload.toLowerCase();
      const filteredusers = state.groups.filter((group) => {
        return (
          group.groupName.toLowerCase().includes(searchString) ||
          group.groupId.toString().includes(searchString)
        );
      });
      return {
        ...state,
        groupsList: filteredusers.length > 0 ? filteredusers : [],
      };
    }
    default:
      return { ...state };
  }
};
