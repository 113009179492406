import { useReducer } from "react";
import { initialState } from "./Users.model";
import { UsersReducer } from "./Users.reducers";
import { UsersServices } from "./Users.services";
import { USERS_ACTIONS } from "./Users.actions";
import { AssetViewServices } from "pages/AssetView/AssetView.services";

export const useUsersViewInitialization = () => {
  const [state, dispatch] = useReducer(UsersReducer, initialState);
  const services = UsersServices();
  const assetServices = AssetViewServices();
  services.getGroupdetails = assetServices.getThingDetails;
  const actions = UsersActions(dispatch, services);
  return { state, actions };
};

const UsersActions = (dispatch, services) => {
  /** API Call: Get all users list */
  const actions = {
    getAllUsers: async () => {
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAllUsers();
      if (response?.error) {
        dispatch({
          type: USERS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: USERS_ACTIONS.USERS,
          payload: response?.data ?? [],
        });
      }
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: false,
      });
    },
    changeView: (viewType) => {
      dispatch({
        type: USERS_ACTIONS.SELECTEDVIEW,
        payload: viewType,
      });
    },
    setSelectedUser: (user) => {
      dispatch({
        type: USERS_ACTIONS.SELECTEDVIEW,
        payload: user,
      });
    },
    searchUsers: (searchString) => {
      dispatch({
        type: USERS_ACTIONS.SEARCHUSER,
        payload: searchString,
      });
    },
    editUser: (user) => {
      dispatch({
        type: USERS_ACTIONS.EDITUSER,
        payload: user,
      });
    },
    getHierarchyList: async () => {
      const response = await services.getHierarchy();
      if (response?.error) {
        dispatch({
          type: USERS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: USERS_ACTIONS.HIERARCHYLIST,
          payload: response?.data ?? [],
        });
      }
    },
    fetchGroupDetails: async (groupId) => {
      const response = await services.getGroupdetails(groupId);
      return response;
    },
    removeError:() => {
      dispatch({
        type: USERS_ACTIONS.ERROR,
        payload: null,
      });
    },
    updateUserAccessInfo: async (id, userInfo, previewView) => {
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.editUserAccess(id, userInfo);
      if (response?.error) {
        dispatch({
          type: USERS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: USERS_ACTIONS.ADDUPDATEUSER,
          payload: { view: previewView, user: userInfo },
        });
      }
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: false,
      });
    },
    addNewUserAccess: async (userInfo, previewView) => {
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.addUserAccess(userInfo);
      if (response?.error) {
        dispatch({
          type: USERS_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        userInfo.id = response.data.id
        dispatch({
          type: USERS_ACTIONS.ADDUPDATEUSER,
          payload: { view: previewView, user: userInfo },
        });
      }
      dispatch({
        type: USERS_ACTIONS.LOADING,
        payload: false,
      });
    },
    /** Validate user if true user exists else not exists */
    validateUser: async (userId) => {
      const response = await services.validateUser(userId);
      return response
    },
  };
  return actions;
};
