import { useContext } from "react";
import UserContext from "common/Context/UserContext";
import { useNavigate } from "react-router-dom";

function AccessDenied() {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <section className="py-3 py-md-5 min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
                <span className="display-2 fw-bold">Access</span>
                <span className="display-2 fw-bold">Denied</span>
              </h2>
              <p className="mb-5">
                You do not have permission to view this page.
              </p>

              <input
                className="btn bsb-btn-5xl btn-dark rounded-pill px-5 fs-6 m-0"
                type="button"
                onClick={() => {
                  navigate("/");
                  logout();
                }}
                value={"Logout"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AccessDenied;
