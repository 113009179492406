import { useEffect } from "react";
import PropTypes from "prop-types";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

const ThingTypeList = ({ thingTypes, handleAddNewThingType, handleEditThingType, fetchAllThingTypes, handlePreviewThingType }) => {
  const { constants, tableHeaders } = INFRAMARK_CONSTANTS.thingTypeDetails;
  useEffect(() => {
    if (thingTypes === null) {
      fetchAllThingTypes()
    }
  }, [thingTypes, fetchAllThingTypes])

  return (
    <div className="admin-panel">
      <div className="header-user">
        <div className="actions" style={{ display: "flex" }}>
          <h4>Thing Type List</h4>
          <button
            className="add-new add-new-bg"
            onClick={() => {
              handleAddNewThingType(constants.ADDTHINGTYPEVIEW);
            }}
          >
            <i className="bi bi-plus-circle"></i> Add New Thing Type
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeaders.thingType.map((title) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {thingTypes?.sort((a,b) => a.thingTypeId - b.thingTypeId).map((thingtype) => (
            <tr key={thingtype.thingTypeId}>

              <td>{thingtype.thingType}</td>
              <td>{thingtype.displayPage}</td>
              <td className="toggle">
                <label className="switch">
                  <input type="checkbox" disabled checked={thingtype.thingTypeIsActive} />
                  {""}
                  <span className="slider"></span>
                </label>
              </td>
              <td>
                <button aria-label="editThingType" className="edit-button" onClick={() => { handleEditThingType(thingtype) }}>
                  <i className="bi bi-pencil-square"></i>
                </button>
                <button aria-label="previewThingType" className="edit-button" onClick={() => { handlePreviewThingType(thingtype) }}>
                  <i className="bi bi-eye-fill"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ThingTypeList;

ThingTypeList.propTypes = {
  thingTypes: PropTypes.array,
  handleAddNewThingType: PropTypes.func.isRequired,
  handleEditThingType: PropTypes.func.isRequired,
  fetchAllThingTypes: PropTypes.func.isRequired,
  handlePreviewThingType: PropTypes.func.isRequired,
};