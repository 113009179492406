import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import PropTypes from "prop-types";

const GroupsList = ({
  groups,
  handleCreateGroup,
  handelEditGroups,
  handelPreviewGroups,
  handleSearchGroup
}) => {
  const { tableHeaders } = INFRAMARK_CONSTANTS.groups;

  return (
    <div className="admin-panel">
      <div className="header-user">
        <div className="actions" style={{ display: "flex" }}>
          <h4>Groups List</h4>
          <button className="add-new add-new-bg" onClick={handleCreateGroup}>
            <i className="bi bi-plus-circle"></i> Add New Group
          </button>
        </div>
        <div className="search">
          <button className="search-button btn-user-bg form-control">
            <input
              className="btn-user-bg"
              type="text"
              placeholder="Search Group.."
              onChange={(search) => {
                handleSearchGroup(search.target.value);
              }}
            />
            <i className="bi bi-search"></i>
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {tableHeaders.list.map((headerTitle) => (
              <th key={headerTitle}>{headerTitle}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groups?.map((group) => {
            return (
              <tr key={group.groupId}>
                <td>{group.groupId}</td>
                <td>{group.groupName}</td>
                <td className="toggle">
                  <label className="switch">
                    <input type="checkbox" disabled checked={group.isActive} />
                    <span className="slider"></span>
                    {""}
                  </label>
                </td>
                <td>
                  <button
                    className="edit-button"
                    aria-label="Edit Group"
                    onClick={() => {
                      handelEditGroups(group);
                    }}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                  <button
                    className="edit-button"
                    aria-label="View Group"
                    onClick={() => {
                      handelPreviewGroups(group);
                    }}
                  >
                    <i className="bi bi-eye-fill"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GroupsList;

GroupsList.propTypes = {
  groups: PropTypes.array,
  handleCreateGroup: PropTypes.func.isRequired,
  handelEditGroups: PropTypes.func.isRequired,
  handelPreviewGroups: PropTypes.func.isRequired,
  handleSearchGroup:PropTypes.func.isRequired,
};
