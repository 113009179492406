import { useContext, useEffect } from "react";
import BaseComponent from "common/Components/BaseComponent";
import AppContext from "common/Context/AppContext";

const Dashboard = () => {
  const { updateNavigationItem } = useContext(AppContext);
  useEffect(() => {
    updateNavigationItem("Dashboard");
  }, []);
  return (
    <BaseComponent>
      <div>Dashboard</div>
    </BaseComponent>
  );
};

export default Dashboard;
