import {
  getRequest,
  postRequest,
  putRequest,
  uploadImage,
  updateImage,
} from "common/HttpClient/http-utility";
import { base64ToBlob } from "common/Utilities/Utilities.functions";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";

export const ThingTypeServices = () => {
  const service_paths = INFRAMARK_CONSTANTS.thingTypeDetails.services;

  const services = {
    /** API Call to get all ThingTypes */
    getAllThingTypes: async () => {
      const response = await getRequest(
        `${service_paths.thingTypeCategoryDetails}`
      );
      return response;
    },
    getThingTypeByID: async (id) => {
      const response = await getRequest(
        `${service_paths.getThingCategoryByID}${id}`
      );
      return response;
    },
    createThingType: async (thingtypeObj) => {
      const response = await postRequest(
        service_paths.createThingType,
        thingtypeObj
      );
      return response;
    },
    updateThingType: async (thingtype) => {
      const response = await putRequest(
        `${service_paths.editThingType}${thingtype.thingType.thingTypeId}`,
        thingtype
      );
      return response;
    },
    /** API Call to get all images using ThingType Id */
    getAllThingTypeImagesByTypeId: async (thing_type_id) => {
      const response = await getRequest(
        `${service_paths.thingTypeImages}${thing_type_id}`
      );
      return response;
    },
    uploadThingTypeImage: async (imageData) => {
      const data = new FormData();
      data.append("thingImageName", imageData.thingImageName);
      data.append("thingImageFilename", imageData.thingImageFilename);
      data.append("thingTypeId", imageData.thingTypeId);
      data.append("isActive", imageData.isActive);
      data.append("createdBy", imageData.createdBy);
      data.append("image", imageData.image);
      const response = await uploadImage(
        data,
        service_paths.createThingTypeImage
      );
      return response;
    },
    updateThingTypeImage: async (imageData) => {
      const data = new FormData();
      data.append("thingImageName", imageData.thingImageName);
      data.append("thingImageFilename", imageData.thingImageFilename);
      data.append("thingTypeId", imageData.thingTypeId);
      data.append("isActive", imageData.isActive);
      data.append("modifiedBy",imageData.modifiedBy ?? imageData.createdBy)
      data.append("image", base64ToBlob(imageData.thingImage, 'image/png'));
      const response = await updateImage(
        data,
        `${service_paths.createThingTypeImage}/${imageData.thingImageId}`
      );
      return response;
    },
  };
  return services;
};
