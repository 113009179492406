import "./ErrorPage.styles.css";
import PropTypes from "prop-types";

const ErrorPage = ({ errorHeading = "Error", errorValue, handleGoBack, buttonTitle="Go back" }) => {
  return (
    <section className=" py-md-5 min-vh-100 error-page">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <h3 className="h2 mb-2 text-danger">{errorHeading}</h3>
            <p className="mb-5 text-dark">
              {errorValue}
            </p>
            {handleGoBack != undefined && <button onClick={handleGoBack}>{buttonTitle}</button>}
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default ErrorPage;
ErrorPage.propTypes = {
  errorHeading: PropTypes.string,
   errorValue: PropTypes.string.isRequired,
   handleGoBack: PropTypes.func,
   buttonTitle:PropTypes.string
}
