import { useContext } from "react";
import UserContext from "common/Context/UserContext";
import { useNavigate } from "react-router-dom";

const UserInfo = () => {
  const { userProfile, logout } = useContext(UserContext);
  const { profile, isLoggedin } = userProfile;
  const navigate = useNavigate();

  return (
    <>
      {isLoggedin && (
        <>
          <div className="ml-auto d-flex ">
            <span className="user-info d-block mb-1" style={{textTransform:'capitalize'}}>
              {profile.name}
              <br />
              <span className="user-role d-block">{profile.role}</span>
            </span>
          </div>
          <div className="align-items-center">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle user-icon-button"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: "white" }}
              >
                <i
                  className="bi bi-person-circle h1"
                  style={{ color: "white" }}
                ></i>
              </button>
              <ul className="dropdown-menu user-info-dropdown">
                <li>
                  <button className="dropdown-item" onClick={() => {}}>
                    Profile
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      navigate("/");
                      logout();
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserInfo;
