// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hierarchy {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  margin-bottom: 4px;
}

.hierarchy:hover {
  text-decoration: underline;
}

.item-selected {
  color: var(--theme-color) !important;
  font-size: 1.25rem;
}
.item-selected:hover {
  text-decoration: underline;
  text-decoration-color: var(--theme-color) !important;
}

.item-not-selected {
  background: none;
}

.hierarchy-button {
  outline: none;
  color: black;
  background: none;
  border: none;
  font-family: "InframarkFont", Arial, Helvetica, sans-serif !important;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.hierarchy-add-button {
  outline: none;
  color: black;
  background: none;
  border: none;
  font-weight: bold;
  margin-left: auto;
  font-size: 1.2rem;
}
.hierarchy-children {
  margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AssetView/Components/AssetMenu/Components/AssetHierarchyItem/AssetHierarchyItem.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,oDAAoD;AACtD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,qEAAqE;EACrE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".hierarchy {\n  cursor: pointer;\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  white-space: nowrap;\n  margin-bottom: 4px;\n}\n\n.hierarchy:hover {\n  text-decoration: underline;\n}\n\n.item-selected {\n  color: var(--theme-color) !important;\n  font-size: 1.25rem;\n}\n.item-selected:hover {\n  text-decoration: underline;\n  text-decoration-color: var(--theme-color) !important;\n}\n\n.item-not-selected {\n  background: none;\n}\n\n.hierarchy-button {\n  outline: none;\n  color: black;\n  background: none;\n  border: none;\n  font-family: \"InframarkFont\", Arial, Helvetica, sans-serif !important;\n  display: flex;\n  align-items: center;\n  padding-right: 10px;\n}\n\n.hierarchy-add-button {\n  outline: none;\n  color: black;\n  background: none;\n  border: none;\n  font-weight: bold;\n  margin-left: auto;\n  font-size: 1.2rem;\n}\n.hierarchy-children {\n  margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
