import { httpClient } from "./http-client";

const getRequest = async (url_path) => {
  try {
    const reponseData = await httpClient.get(url_path);
    return processResponse(reponseData);
  } catch (error) {
    return {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
};
const getAllRequests = async (url_paths) => {
  try {
    const requests = url_paths.map((url) => httpClient.get(url));
    const responses = await Promise.all(requests);
    const processedRequest = responses.map((responseData) =>
      processResponse(responseData)
    );
    return processedRequest;
  } catch (error) {
    return [
      {
        status: error.response.status ?? 500,
        error: error.response.data.message ?? error.message,
      },
    ];
  }
};
const postRequest = async (url_path, data) => {
  try {
    const reponseData = await httpClient.post(url_path, data);

    return processResponse(reponseData);
  } catch (error) {
    return {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
};
const putRequest = async (url_path, data) => {
  try {
    const reponseData = await httpClient.put(url_path, data);
    return processResponse(reponseData);
  } catch (error) {
    return {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
};

const processResponse = (reponseData) => {
  let response = {};
  try {
    if (reponseData.status === 200 || reponseData.status === 201) {
      response = {
        data: reponseData.data,
        status: reponseData.status,
      };
    } else {
      response = {
        status: reponseData.status,
        error: reponseData.error ?? "Please try again after some time",
      };
    }
  } catch (error) {
    response = {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
  return response;
};

const uploadImage = async (formData, url_path) => {
  try {
    const response = await httpClient.post(url_path, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return processResponse(response);
  } catch (error) {
    return {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
};
const updateImage = async (formData, url_path) => {
  try {
    const response = await httpClient.put(url_path, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return processResponse(response);
  } catch (error) {
    return {
      status: error.response.status ?? 500,
      error: error.response.data.message ?? error.message,
    };
  }
};
export { getRequest, postRequest, putRequest, getAllRequests, uploadImage, updateImage };
