import { useState, useEffect } from "react";
import "./AssetHierarchyItem.styles.css";
import PropTypes from "prop-types";

const AssetHierarchyItem = ({
  asset,
  selectedAsset,
  handleAssetSelection,
  handleAddNewAsset,
  isParentExpanded,
  isSearchEnabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(
    isParentExpanded || isSearchEnabled
  );
  useEffect(() => {
    setIsExpanded(isParentExpanded || isSearchEnabled);
  }, [isSearchEnabled]);

  const handleExpandCollapse = () => {

    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <div
        className={`hierarchy ${isExpanded ? "active" : ""} ${
          asset.thing_id === selectedAsset?.thingId
            ? "item-selected"
            : "item-not-selected"
        }`}
      >
        <button
          name="dropdown"
          onClick={handleExpandCollapse}
          className={`hierarchy-button ${isExpanded ? "active" : ""} ${
            asset.thing_id === selectedAsset?.thingId
              ? "item-selected"
              : "item-not-selected"
          }`}
        >
          {isExpanded ? (
            <i className="bi bi-caret-down-fill"></i>
          ) : (
            <i className="bi bi-caret-right-fill"></i>
          )}
        </button>
        <button
          name="title"
          className={`hierarchy-button ${isExpanded ? "active" : ""} ${
            asset.thing_id === selectedAsset?.thingId
              ? "item-selected"
              : "item-not-selected"
          }`}
          onClick={() => {
            handleAssetSelection(asset.thing_id);
          }}
        >
          {asset?.thing_name}
        </button>
        <button
          name="add"
          onClick={() => {
            handleAddNewAsset(asset);
          }}
          className={`hierarchy-add-button  ${isExpanded ? "active" : ""} ${
            asset.thing_id === selectedAsset?.thingId
              ? "item-selected"
              : "item-not-selected"
          }`}
        >
          <i className="bi bi-plus"></i>
        </button>
      </div>
      {isExpanded && asset?.children && (
        <div className="hierarchy-children">
          {asset?.children
            .sort(
              (a, b) =>
                parseInt(a.thing_display_order) - parseInt(b.thing_display_order)
            )
            ?.map((child) => (
              <AssetHierarchyItem
                key={child.thing_id}
                asset={child}
                selectedAsset={selectedAsset}
                handleAssetSelection={handleAssetSelection}
                handleAddNewAsset={handleAddNewAsset}
                isParentExpanded={false}
                isSearchEnabled={isSearchEnabled}
              />
            ))}
        </div>
      )}
    </div>
  );
};

AssetHierarchyItem.propTypes = {
  asset: PropTypes.object.isRequired,
  selectedAsset: PropTypes.object,
  handleAssetSelection: PropTypes.func.isRequired,
  handleAddNewAsset: PropTypes.func.isRequired,
  isParentExpanded:PropTypes.bool.isRequired,
  isSearchEnabled:PropTypes.bool.isRequired,
};

export default AssetHierarchyItem;
