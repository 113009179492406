import { useState, useMemo } from "react";
import AppContext from "./AppContext";
import { APP_INFO } from "./Context.model";
import PropTypes from "prop-types";

const AppContextProvider = ({ children }) => {
  const [navigationItems, setNavigationItems] = useState(
    APP_INFO.navigation_items
  );
  const updateNavigationItem = (itemTitle) => {
    const updatedItems = navigationItems.map((item) => {
      if (item.title === itemTitle) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setNavigationItems(updatedItems);
  };

 
  const contextValues = useMemo(() => {
    return {
      navigationItems,
      updateNavigationItem,
    };
  });
  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

export default AppContextProvider;
AppContextProvider.propTypes = {
  children: PropTypes.any
}