/**
 * Login page with user name and password
 */
import "./Login.styles.css";
import Loading from "common/Components/Loading";

export default function Login() {
 
  return (
    <div className="col-sm-12 row ">
      <Loading />
    </div>
  );
}
