/** User reducer actions */
export const USERS_ACTIONS = {
    LOADING : "loading",
    ERROR: "error",
    LOADINGHIERARCHY:"hierarchy_loading",
    ERRORHIERARCHY:"error_hierarchy",
    USERS: "users",
    USERLIST: "user_list",
    SEARCHUSER:'search_user',
    ADDUSER:'add_user',
    ADDUPDATEUSER:'add_update_user',
    EDITUSER:'edit_user',
    SELECTEDVIEW:'selected_view',
    SELECTEDUSER:'selected_user',
    HIERARCHYLIST:'hierarchy_list',
}