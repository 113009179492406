/** Utilities file for AssetView support functions */

/**Building Hierarchy tree using response assets list */
const buildAssetHierarchy = (items) => {
  const baseAssetTree = {};
  items.forEach((item) => {
    baseAssetTree[item.thingId] = { ...item, children: [], isExpanded: false };
  });
  const rootAssetTree = [];
  items.forEach((item) => {
    if (item.parentId === 0) {
      rootAssetTree.push(baseAssetTree[item.thingId]);
    } else if (baseAssetTree[item.parentId]) {
      baseAssetTree[item.parentId].children.push(baseAssetTree[item.thingId]);
    } else {
      rootAssetTree.push(baseAssetTree[item.thingId]);
    }
  });
  const loadInitialValues =
    rootAssetTree.length > 0
      ? toggleAssetTreeNode(rootAssetTree[0].thingId, rootAssetTree)
      : rootAssetTree;
  return loadInitialValues;
};

/** Set hierarchy tree node toggle enable and disable */
const toggleAssetTreeNode = (thingId, assets) => {
  return assets.map((node) => {
    if (node.thing_id === thingId) {
      return { ...node, isExpanded: !node.isExpanded };
    } else if (node.children && node.children.length > 0) {
      return { ...node, children: toggleAssetTreeNode(thingId, node.children) };
    } else {
      return node;
    }
  });
};
/** On Search expand hierarchy tree */
const expandAssetTree = (targetId, assets) => {
  let found = false;
  const expandedAssets = assets.map((node) => {
    if (node.thing_id === targetId) {
      found = true;
      return { ...node, isExpanded: true };
    } else if (node.children && node.children.length > 0) {
      const result = expandAssetTree(targetId, node.children);
      if (result.found) {
        found = true;
        return { ...node, isExpanded: true, children: result.expandedAssets };
      }
    }
    return { ...node };
  });
  return { expandedAssets, found };
};
/** Add new asset to asset hierarchy tree */
const insertIntoAssetTree = (new_asset, assets) => {
  let found = false;
  const newAssets = assets.map((node) => {
    if (node.thing_id === new_asset.parentId) {
      found = true;
      return {
        ...node,
        isExpanded: true,
        children: [...node.children, new_asset],
      };
    } else if (node.children && node.children.length > 0) {
      const result = insertIntoAssetTree(new_asset, node.children);
      if (result.found) {
        found = true;
        return { ...node, isExpanded: true, children: result.newAssets };
      }
    }
    return { ...node };
  });
  return { newAssets, found };
};
/** Update asset values in hierarchy */
const updateAssetInAssetTree = (updated_asset, assets) => {
  let found = false;
  const updatedAssets = assets.map((node) => {
    if (node.thing_id === updated_asset.thing_id) {
      found = true;
      return {
        ...node,
        isExpanded: true,
        parent_id: updated_asset.parentId,
        thing_name: updated_asset.thing_name,
        thing_display_order: updated_asset.thing_display_order,
      };
    } else if (node.children && node.children.length > 0) {
      const result = updateAssetInAssetTree(updated_asset, node.children);
      if (result.found) {
        found = true;
        return { ...node, isExpanded: true, children: result.updatedAssets };
      }
    }
    return { ...node };
  });
  return { updatedAssets, found };
};
export {
  buildAssetHierarchy,
  toggleAssetTreeNode,
  expandAssetTree,
  insertIntoAssetTree,
  updateAssetInAssetTree,
};
