import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './CustomAlert.style.css';

const CustomAlert = ({
  title,
  show,
  cancelText = 'Cancel',
  okText = 'Ok',
  message,
  onCancel,
  onOkay = () => {}
}) => {

  return (
    <Modal show={show} centered >
      <Modal.Header >
        <Modal.Title style={{ fontWeight: 'bold' }} >{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <h5 style={{ textAlign: 'center' }}>{message}</h5>
      </Modal.Body>
      <Modal.Footer>
        {onCancel && (
          <Button variant="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        {onOkay && (<Button variant="primary" onClick={onOkay}>
          {okText}
        </Button>)}
      </Modal.Footer>
    </Modal>

  );
};

export default CustomAlert;
