/** Initial state  */
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants"
export const initialState = {
    loading:false,
    error:null,
    loadingHierarchy:false,
    errorHierarchy:null,
    users: null,
    usersList: null,
    selectedUser: null,
    isAddUserSelected:false,
    isEditUserSelected: false,
    selectedView:INFRAMARK_CONSTANTS.users.constants.USERSLISTVIEW,
    hierarchylist:null
}