import { useReducer } from "react";
import { initialState } from "./ThigType.model";
import { ThingtypeReducer } from "./ThingType.reducers";
import { ThingTypeServices } from "./ThingType.services";
import { THINGTYPE_ACTIONS } from "./ThingType.actions";

export const useThingtypeViewInitialization = () => {
  const [state, dispatch] = useReducer(ThingtypeReducer, initialState);
  const services = ThingTypeServices();
  const actions = ThingTypeActions(dispatch, services);
  return { state, actions };
};

const ThingTypeActions = (dispatch, services) => {
  /** API Call: Get all ThingTypes list */
  const actions = {
    getThingTypes: async () => {
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getAllThingTypes();

      if (response?.error) {
        dispatch({
          type: THINGTYPE_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: THINGTYPE_ACTIONS.THINGTYPES,
          payload: response?.data ?? [],
        });
      }
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: false,
      });
    },
    changeViewStatus: (viewType) => {
      dispatch({
        type: THINGTYPE_ACTIONS.SELECTEDVIEW,
        payload: viewType,
      });
    },
    setSelectedThingType: async (thingtype) => {
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getThingTypeByID(thingtype.thingTypeId);
      if (response?.error) {
        dispatch({
          type: THINGTYPE_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: THINGTYPE_ACTIONS.SELECTEDTHINGTYPE,
          payload: response?.data,
        });
      }
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: false,
      });
    },
    setDefaultSelectedThingType: (value) => {
      dispatch({
        type: THINGTYPE_ACTIONS.SELECTEDTHINGTYPE,
        payload: value,
      });
    },
    searchThingType: (searchString) => {
      dispatch({
        type: THINGTYPE_ACTIONS.SEARCHTHINGTYPE,
        payload: searchString,
      });
    },
    addThingType: async (data, viewStatus, fetchAllList) => {
      const typeData = {...data}
      if (data.images) {
        delete typeData["images"]
      } 
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.createThingType(typeData);

      if (response?.error) {
        dispatch({
          type: THINGTYPE_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: THINGTYPE_ACTIONS.SELECTEDVIEW,
          payload: viewStatus,
        });
        fetchAllList(response?.data);
      }
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: false,
      });
    },
    editThingType: async (thingType, viewStatus, fetchAllList) => {
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.updateThingType(thingType);

      if (response?.error) {
        dispatch({
          type: THINGTYPE_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: THINGTYPE_ACTIONS.SELECTEDVIEW,
          payload: viewStatus,
        });
        fetchAllList();
      }
      dispatch({
        type: THINGTYPE_ACTIONS.LOADING,
        payload: false,
      });
    },
    /** API Call: Get all thing images by Type ID */
    fetchAllThingTypeImages: async (thing_type_id) => {
      const response = await services.getAllThingTypeImagesByTypeId(
        thing_type_id
      );
      return response;
    },
    addThingTypeImage: async (data) => {
      const response = await services.uploadThingTypeImage(data);
      return response;
    },
    updateThingTypeImage: async (data) => {
      const response = await services.updateThingTypeImage(data);
      return response;
    }
  };
  return actions;
};
