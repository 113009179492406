import BrandLogo from "./Components/BrandLogo";
import NavigationItems from "./Components/NavigationItems";
import UserInfo from "./Components/UserInfo";
import "./Header.styles.css";

const Header = () => {
  return (
    <div className="header-top header-bg navbar navbar-expand-lg navbar-light">
      <BrandLogo />
      <NavigationItems />
      <UserInfo />
    </div>
  );
};

export default Header;
