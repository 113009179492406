import { useReducer } from "react";
import { initialState } from "./CreateUser.model";
import { CREATEUSER_ACTIONS } from "./CreateUser.actions";
import { CreateUserReducer } from "./CreateUser.reducers";
import { CreateUserServices } from "./CreateUser.services";
import { GroupsServices } from "../Groups/Groups.services";

export const useCreateUserInitialization = () => {
  const [state, dispatch] = useReducer(CreateUserReducer, initialState);
  const groupServices = GroupsServices();
  const services = CreateUserServices();
  services.getAllGroups = groupServices.getAllGroups;
  const actions = CreateUserActions(dispatch, services);
  return { state, actions };
};

const CreateUserActions = (dispatch, services) => {
  /** API Call: Get all groups list */
  const actions = {
    getAllGroups: async () => {
      const response = await services.getAllGroups();
      return response?.data ?? [];
    },
    /**Create new User */
    addNewUser: async (userInfo, onSuccessCallBack) => {
      dispatch({
        type: CREATEUSER_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.createNewUser(userInfo);
      if (response?.error) {
        dispatch({
          type: CREATEUSER_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        if (response.data) {
          onSuccessCallBack(response.data);
        }
      }
      dispatch({
        type: CREATEUSER_ACTIONS.LOADING,
        payload: false,
      });
    },
    /** Validate user if true user exist else not exist */
    validateUser: async (userId) => {
      const response = await services.validateUser(userId);
      return response
    },
    /** Reset error to null */
    refreshError: () => {
      dispatch({
        type: CREATEUSER_ACTIONS.ERROR,
        payload: null,
      });
    },
  };
  return actions;
};
