import { ALERTVIEW_ACTIONS } from "./AlertView.actions";

export const AlertViewReducer = (state, action) => {
  switch (action.type) {
    case ALERTVIEW_ACTIONS.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ALERTVIEW_ACTIONS.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ALERTVIEW_ACTIONS.ALERTS:
      return {
        ...state,
        alertConfigurations: action.payload,
        error: null,
      };
    case ALERTVIEW_ACTIONS.CHANGEVIEWSTATUS:
      return {
        ...state,
        viewStatus: action.payload,
      };
    case ALERTVIEW_ACTIONS.SELECTEDALERT:
      return {
        ...state,
        selectedAlertConfiguration: action.payload,
      };
    case ALERTVIEW_ACTIONS.SELECTEDCONDITION:
      return {
        ...state,
        selectedAlertCondition: action.payload,
      };
      case ALERTVIEW_ACTIONS.SELECTEDCATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
      
    default:
      return { ...state };
  }
};
