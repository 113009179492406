import { useEffect, useContext, lazy, Suspense } from "react";
import { useAssetViewInitialization } from "./AssetView.hooks";
import { AssetMenu, AssetDetails } from "./Components";
import AppContext from "common/Context/AppContext";
import BaseComponent from "common/Components/BaseComponent";
/** Lazy loading components */
const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));

export default function AssetView() {
  const { state, actions } = useAssetViewInitialization();
  const { updateNavigationItem } = useContext(AppContext);

  useEffect(() => {
    /* Fetch all assets on page load */
    if (actions) {
      actions.getAllAssets();
    }
    updateNavigationItem("Assets");
  }, []);

  useEffect(() => {
    /* load root asset on page load  */
    if (state.assets.length > 0 && state.selectedAsset === null) {
      actions.setSelectedNode(state.assets[0].thing_id);
    }
  }, [state.assets]);

  /** on select asset load Asset details view */
  const loadAssetView = () => {
    if (state.loading) {
      return (
        <Suspense fallback={<Loading />}>
          <Loading />
        </Suspense>
      );
    } else {
      return <AssetDetails state={state} actions={actions} />;
    }
  };
  if (state.errorMenu) {
    return (
      <Suspense fallback={<Loading />}>
        <ErrorPage errorValue={state.errorMenu}/>
      </Suspense>
    );
  }
  return (
    <BaseComponent>
      {state.loadingMenu ? (
        <Loading />
      ) : (
        <div className="main d-flex">
          <AssetMenu
            assets={state.assets}
            selectedAsset={state.selectedAsset}
            handleAssetSelection={(thing_id) => {
              actions.setSelectedNode(thing_id);
            }}
            handleAddNewAsset={(parent_asset) => {
              actions.addNewAsset({
                parentThingName: parent_asset.thing_name,
                parentId: parent_asset.thing_id,
              });
            }}
          />
          {loadAssetView()}
        </div>
      )}
    </BaseComponent>
  );
}
