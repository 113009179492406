/** User reducer actions */
export const THINGTYPE_ACTIONS = {
    LOADING : "loading",
    ERROR: "error",
    THINGTYPES: "thingtypes",
    ADDTHINGTYPE:'add_thingtype',
    EDITTHINGTYPE:'edit_thingtype',
    SELECTEDVIEW:'selected_view',
    SELECTEDTHINGTYPE:'selected_thingtype',
    SEARCHTHINGTYPE:'search_thingtype',
}