// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` :root {
        --theme-color: #008FD5 !important;
        --mandatory-color: red !important;
        --txt-white-color: white !important; 
        --txt-infra-color: #0d004c !important;  
             
    }
.App {
  text-align: center;
  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;
}
body{
  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;
}
.span-mandatory{
  color: var(--mandatory-color);
  font-size: 12px;
}
.form-control:disabled {
  background-color: #F6FAFC !important;
 }
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"CAAC;QACO,iCAAiC;QACjC,iCAAiC;QACjC,mCAAmC;QACnC,qCAAqC;;IAEzC;AACJ;EACE,kBAAkB;EAClB,qEAAqE;AACvE;AACA;EACE,qEAAqE;AACvE;AACA;EACE,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,oCAAoC;CACrC","sourcesContent":[" :root {\n        --theme-color: #008FD5 !important;\n        --mandatory-color: red !important;\n        --txt-white-color: white !important; \n        --txt-infra-color: #0d004c !important;  \n             \n    }\n.App {\n  text-align: center;\n  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;\n}\nbody{\n  font-family: 'InframarkFont', Arial, Helvetica, sans-serif !important;\n}\n.span-mandatory{\n  color: var(--mandatory-color);\n  font-size: 12px;\n}\n.form-control:disabled {\n  background-color: #F6FAFC !important;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
