import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import UserContext from "common/Context/UserContext";
import "./AddEditUser.styles.css";
import HierarchyModel from "./Component/HierarchyModel";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import Select from "react-select";
import PropTypes from "prop-types";
import CustomAlert from "common/Components/CustomAlert";

const AddEditUser = ({
  selectedUser,
  hierarchylist,
  isEdit = false,
  handleCancel,
  getHierarchyList,
  getGroupDetails,
  updateUserAccess,
  addNewUserAccess,
  validateUser,
}) => {
  const { userProfile } = useContext(UserContext);
  const [showHierarchy, setShowHierarchy] = useState(false);
  const [isUserValidated, setIsUserValidated] = useState(isEdit);
  const [show, setShow] = useState(false);
  const [showMandetory, setShowMandetory] = useState(false);
  const [showGroupName, setShowGroupName] = useState(false);
  
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      id: isEdit ? selectedUser.id : "",
      groupName: isEdit ? selectedUser.groupName : userProfile.profile.groupId,
      groupId: isEdit ? selectedUser.groupId : userProfile.profile.groupId,
      accessTo: isEdit ? selectedUser.accessTo : "All",
      userId: isEdit ? selectedUser.userId : "",
      thingId: isEdit ? selectedUser.thingId : "",
      thingName: isEdit ? selectedUser.thingName : "Select Thing",
      isActive: isEdit ? selectedUser.isActive : true,
    },
  });

  


  useEffect(() => {
    if (isEdit) {
      setValue("modifiedBy", userProfile.profile.userid);
    } else {
      fetchGroupdetails();
      setValue("createdBy", userProfile.profile.userid);
    }
  }, [selectedUser]);

  const fetchGroupdetails = async () => {
    getGroupDetails(userProfile.profile.groupId)
      .then((resposnse) => {
        if (resposnse.status === 200) {
          setValue("groupName", resposnse.data.thingName);
        } else {
          setValue("groupName", userProfile.profile.groupId);
          setShowGroupName(true);
        }
      })
      .catch(() => {
        setValue("groupName", userProfile.profile.groupId);
        setShowGroupName(true);
      });
  };

  const validateUserId = async (value) => {
    if (value?.length <= 0) {
      return;
    }
    try {
      // check if the user ID already exists if true exists else not exists
      const response = await validateUser(value);
      setIsUserValidated(true);
      if (response?.error) {
        setError("userId", {
          type: "manual",
          message: response?.error ?? "Failed to check User ID",
        });
      } else if (!response?.data) {
        setError("userId", {
          type: "manual",
          message: "The user does not exist in the group.",
        });
      } else {
        clearErrors("userId");
      }
    } catch (error) {
      setIsUserValidated(true);
      setError("userId", {
        type: "manual",
        message: "Failed to check User ID",
      });
    }
  };

  const onSubmit = (data) => {
    if (
      data.accessTo.length === 0 ||
      data.thingId.length === 0 ||
      data.userId.length === 0
    ) {
      setShowMandetory(true);
      return;
    }
    data["userId"] = data.userId.toLowerCase();
    handleAPICall(data);
  };
  const handleAPICall = async (data) => {
    if (isEdit) {
      updateUserAccess(
        data.id,
        data,
        INFRAMARK_CONSTANTS.users.constants.USERSLISTVIEW
      );
    } else {
      addNewUserAccess(data, INFRAMARK_CONSTANTS.users.constants.USERSLISTVIEW);
    }
  };

  

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);


  return (
    <div className="add-user-form">
      {showGroupName && (
        <CustomAlert
          show={showGroupName}
          title={"Alert"}
          onOkay={() => {
            setShowGroupName(false);
          }}
          message="Unable to fetch group name"
        />
      )}

      {showMandetory && (
        <CustomAlert
          show={showMandetory}
          title={"Alert"}
          onOkay={() => {
            setShowMandetory(false);
          }}
          message="All fields are mandetory"
        />
      )}
      <div className="h2-bottom">
        <h2>{isEdit ? "Edit" : "Add"} User Access</h2>
      </div>
      <form className="col-12 row" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 row">
          <div className=" form-group toggle col-12 row">
            <div className="col-2">
              <label>Active</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-3">
              <label className="switch">
                <input type="checkbox" {...register("isActive")} />
                {""}
                <span className="slider"></span>
              </label>
            </div>
            <div className="col-6"></div>
          </div>

          <div className=" form-group col-sm-12 row">
            <div className="col-2">
              <label htmlFor="userid">User Id</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-3">
              <input
                id="userid"
                className="form-control"
                type="text"
                disabled={isEdit}
                style={{
                  textTransform: "lowercase",
                  borderColor: errors?.userId ? "red" : "",
                }}
                {...register("userId", {
                  required: true,
                  onBlur: (e) => validateUserId(e.target.value),
                  onChange: (e) => setIsUserValidated(false),
                })}
                autoComplete="off"
                autoCorrect="off"
              />
              {errors?.userId && <p>{errors?.userId?.message}</p>}
            </div>
            <div className="col-6"></div>
          </div>

          <div className="form-group col-sm-12 row">
            <div className="col-2">
              <label>Select Thing</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-3">
              <button
                type="button"
                className="form-control btn-text-left"
                onClick={() => {
                  setShowHierarchy(true);
                }}
              >
                {getValues("thingName")}
              </button>
            </div>
            <div className="col-6"></div>
          </div>
          <div className=" form-group col-12 row">
            <div className="col-2">
              <label htmlFor="access-to">Access To</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-3">
              <Controller
                name="accessTo"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    closeMenuOnSelect={true}
                    name={name}
                    ref={ref}
                    onBlur={onBlur}
                    onChange={(selectedOption) => {
                      onChange(selectedOption ? selectedOption.value : "");
                    }}
                    value={INFRAMARK_CONSTANTS.routes.userAdminRoutes.filter(
                      (option) => value.includes(option.value)
                    )}
                    options={INFRAMARK_CONSTANTS.routes.userAdminRoutes}
                    className="basic-single"
                    classNamePrefix="select"
                  />
                )}
              />
            </div>
            <div className="col-6"></div>
          </div>
          <div className="form-group col-12 row">
            <div className="col-2">
              <label htmlFor="group">Group</label>
            </div>
            <div className="col-1">:</div>
            <div className="col-3">
              <input
                id="group"
                className="form-control"
                type="text"
                disabled
                {...register("groupName")}
              />
            </div>
            <div className="col-6"></div>
          </div>
          <div className="col-12 row">
            <div className="col-2"></div>
            <div className="col-4" style={{ textAlign: "end" }}>
              <button
                type="button"
                className="btn btn-light btn-cncl"
                onClick={() => {
                  setShow(true);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={Object.keys(errors).length > 0 || !isUserValidated}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
      {show && (
        <CustomAlert
          show={show}
          title={"User Access"}
          message={"Are you sure you want to cancel?"}
          onOkay={() => {
            setShow(false);
            handleCancel();
          }}
          onCancel={() => {
            setShow(false);
          }}
        />
      )}
      {showHierarchy && (
        <HierarchyModel
          showHierarchy={showHierarchy}
          handleShowHierarchy={() => {
            setShowHierarchy(false);
          }}
          getHierarchyList={() => {
            getHierarchyList();
          }}
          hierarchylist={hierarchylist}
          handleSelectHierarchy={(selectedNode) => {
            setShowHierarchy(false);
            setValue("thingId", selectedNode.thing_id);
            setValue("thingName", selectedNode.thing_name);
          }}
        />
      )}
    </div>
  );
};

export default AddEditUser;

AddEditUser.propTypes = {
  selectedUser: PropTypes.object,
  hierarchylist: PropTypes.array,
  isEdit: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  getHierarchyList: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  updateUserAccess: PropTypes.func.isRequired,
  addNewUserAccess: PropTypes.func.isRequired,
  validateUser: PropTypes.func.isRequired,
};
