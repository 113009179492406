import { useReducer } from "react";
import { initialState } from "./AssetView.model";
import { AssetViewReducer } from "./AssetView.reducers";
import { AssetViewServices } from "./AssetView.services";
import { ASSETVIEW_ACTIONS } from "./AssetView.actions";
import {
  toggleAssetTreeNode,
  insertIntoAssetTree, expandAssetTree
} from "./AssetView.utilities";

export const useAssetViewInitialization = () => {
  const [state, dispatch] = useReducer(AssetViewReducer, initialState);
  const services = AssetViewServices();
  const actions = AssetViewActions(dispatch, services);
  return { state, actions };
};

const AssetViewActions = (dispatch, services) => {
  const actions = {
    /** API Call: Get all asset information */
    // getAllAssets: async () => {
    //   dispatch({
    //     type: ASSETVIEW_ACTIONS.LOADING_MENU,
    //     payload: true,
    //   });
    //   const response = await services.getAllThings();
    //   if (response?.error) {
    //     dispatch({
    //       type: ASSETVIEW_ACTIONS.ERROR_MENU,
    //       payload: response?.error ?? "Please try again after some time",
    //     });
    //   } else {
    //     dispatch({
    //       type: ASSETVIEW_ACTIONS.ASSETS,
    //       payload: response?.data.things
    //         ? buildAssetHierarchy(response?.data.things)
    //         : [],
    //     });
    //   }
    //   dispatch({
    //     type: ASSETVIEW_ACTIONS.LOADING_MENU,
    //     payload: false,
    //   });
    // },
    // getAssetHierarchy
    getAllAssets: async () => {
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING_MENU,
        payload: true,
      });
      const response = await services.getAssetHierarchy();
      if (response?.error) {
        dispatch({
          type: ASSETVIEW_ACTIONS.ERROR_MENU,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        const loadInitialValues =
          response?.data.length > 0
            ? toggleAssetTreeNode(response?.data[0].thing_id, response?.data)
            : response?.data;

        dispatch({
          type: ASSETVIEW_ACTIONS.ASSETS,
          payload: loadInitialValues.length > 0 ? loadInitialValues : [],
        });
      }
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING_MENU,
        payload: false,
      });
    },
    /** Expand node when user taps*/
    // setIsNodeExpanded: (thingId, treeData) => {
    //   const response = toggleAssetTreeNode(thingId, treeData);
    //   dispatch({
    //     type: ASSETVIEW_ACTIONS.ASSETS,
    //     payload: response ?? [],
    //   });
    // },
    /** Expand tree when user taps on search results*/
    // expandSearchNode: (expand_to_thing, treeData) => {
    //   const { expandedAssets } = expandAssetTree(expand_to_thing, treeData);

    //   dispatch({
    //     type: ASSETVIEW_ACTIONS.ASSETS,
    //     payload: expandedAssets ?? [],
    //   });
    // },
    /** Save selected list */
    setSelectedNode: async (thing_id) => {
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: true,
      });
      const response = await services.getThingDetails(thing_id);
      if (response?.error) {
        dispatch({
          type: ASSETVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        dispatch({
          type: ASSETVIEW_ACTIONS.ASSETSELECTED,
          payload: response?.data ? response?.data : null,
        });
      }
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: false,
      });
    },
    /** Get Thing Type name from server */
    getThingTypeName: async (thing_type_id) => {
      const response = await services.getThingTypeByID(thing_type_id);
      return response?.data.thingType ?? null;
    },

    /** Get Thing Type name and Category name from server */
    getThingTypeInfoAndTypeCategoryInfo: async (
      thing_type_id,
      thing_type_category_id,
      asset
    ) => {
      const [typeNameResponse, categoryResponse] =
        await services.getTypeInfoAndCategoryInfo(
          thing_type_id,
          thing_type_category_id
        );
      asset.thingType = typeNameResponse.data.thingType;
      asset.thingTypeCategory = thing_type_category_id
        ? categoryResponse.data.thingTypeCategory
        : "--";
      dispatch({
        type: ASSETVIEW_ACTIONS.ASSETSELECTED,
        payload: asset,
      });
    },
    /** Get Thing image based on thing image id */
    getThingImage: async (thing_image_id) => {
      const response = await services.getThingImageByID(thing_image_id);
      return response?.data;
    },
    /** Add new asset: save new asset in reducer */
    addNewAsset: (parent_object = null) => {
      dispatch({
        type: ASSETVIEW_ACTIONS.ADDASSET,
        payload: parent_object,
      });
    },
    /** Edit thing: save edit asset in reducer */
    editAsset: (asset = null) => {
      dispatch({
        type: ASSETVIEW_ACTIONS.EDITASSET,
        payload: asset,
      });
    },
    /** API Call: Get All thing types */
    fetchAllThingTypes: async () => {
      const response = await services.getAllThingTypes();
      return response?.data ?? [];
    },
    /** API Call: Get All thing type categories */
    fetchAllThingTypeCategories: async (thing_type_id) => {
      const response = await services.getAllThingTypeCategories(thing_type_id);
      return response?.data ?? [];
    },
    /** API Call: Get all thing images by Type ID */
    fetchAllThingImages: async (thing_type_id) => {
      const response = await services.getAllThingImagesByTypeId(thing_type_id);
      return response;
    },
    /** API Call: Get thing images by thing image ID */
    fetchThingImageById: async (thing_image_id) => {
      const response = await services.getThingImageByID(thing_image_id);
      return response?.data ?? null;
    },
    /** API Call: Create new asset */
    createAsset: async (asset_details, assetsHirarchy) => {
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: false,
      });
      const response = await services.createNewAsset(asset_details);
      if (response?.error) {
        dispatch({
          type: ASSETVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        const newAsset = {
          thing_id: response.data.thingId,
          parentId: response.data.parentId,
          thing_name: response.data.thingName,
          thing_display_order: response.data.thingDisplayOrder,
          children: [],
          isExpanded: true,
        };
        const { newAssets } = insertIntoAssetTree(newAsset, assetsHirarchy);
        dispatch({
          type: ASSETVIEW_ACTIONS.ASSETS,
          payload: newAssets,
        });
      }
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: false,
      });
      return response;
    },
    /** API Call: Update edited asset */
    updateAssetDetails: async (asset_details) => {
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: false,
      });
      const response = await services.updateAsset(asset_details);
      if (response?.error) {
        dispatch({
          type: ASSETVIEW_ACTIONS.ERROR,
          payload: response?.error ?? "Please try again after some time",
        });
      } else {
        let loadUpdatedValues = response?.data
          if (response?.data.length > 0) {
            const {expandedAssets} = expandAssetTree(asset_details.thing.thingId, response?.data)
            loadUpdatedValues = expandedAssets
          }
        // const updatedAsset = {
        //   thing_id: response.data.thingId,
        //   parentId: asset_details.thing.parentId,
        //   thing_name: asset_details.thing.thingName,
        //   thing_display_order: asset_details.thing.thingDisplayOrder,
        // };
        // const { updatedAssets } = updateAssetInAssetTree(
        //   updatedAsset,
        //   assetsHirarchy
        // );
        // dispatch({
        //   type: ASSETVIEW_ACTIONS.ASSETS,
        //   payload: updatedAssets,
        // });
        dispatch({
          type: ASSETVIEW_ACTIONS.ASSETS,
          payload: loadUpdatedValues.length > 0 ? loadUpdatedValues : [],
        });
      }
      dispatch({
        type: ASSETVIEW_ACTIONS.LOADING,
        payload: false,
      });
      return response;
    },
  };
  return actions;
};
