import { useContext } from "react";
import AppContext from "common/Context/AppContext";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
const NavigationItems = () => {
  const { navigationItems } = useContext(AppContext);
  const navigate = useNavigate();

  const handleTabSelection = (item) => {
    navigate(item.navPath);
  };
  return (
    <div>
      <Navbar className="nav-header-font">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {navigationItems.map((navigationItem) => {
              return (
                <Nav.Link
                  key={navigationItem.title}
                  onClick={() => handleTabSelection(navigationItem)}
                  active={navigationItem.isActive}
                >
                  {navigationItem.title}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavigationItems;
