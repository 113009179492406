/**
 *  Asset detail view 
 *  It handles asset preview functionality and add/edit asset functionality of Asset and properties
 *  Can navigate between Assets, Payload and Alerts
 */

import { Suspense, lazy, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AlertView from "pages/AlertView";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import './AssetDetails.styles.css'
import PropTypes from "prop-types";

/**Lazy loading components */
const Loading = lazy(() => import("common/Components/Loading"));
const AssetPreview = lazy(() => import("../AssetPreview/AssetPreview"));
const AddEditAsset = lazy(() => import("../AddEditAsset/AddEditAsset"));
const tabs = INFRAMARK_CONSTANTS.assets.tabs
const AssetDetails = ({ state, actions }) => {
  const [activeTab, setActiveTab] = useState("assets");
  return (
    <div className="col-10">
      <Tabs
        id="controlled-tab-example"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="tab-padding"
      >
        <Tab eventKey={tabs.assets.key} title={tabs.assets.value}>
          {state.selectedAsset &&
            !state.isAddAssetSelected &&
            !state.isEditAssetSelected && (
              <Suspense fallback={<Loading />}>
                <AssetPreview
                  asset={state.selectedAsset}
                  fetchTypeInfoData={(thing_type_id, thing_type_category_id, asset) => {
                    actions.getThingTypeInfoAndTypeCategoryInfo(thing_type_id, thing_type_category_id, asset)

                  }}
                  fetchAssetImage={actions.getThingImage}
                  handleAddNewAsset={(parent_asset) => {
                    actions.addNewAsset({
                      parentThingName: parent_asset.thingName,
                      parentId: parent_asset.thingId,
                    });
                  }}
                  handleEditAsset={(asset) => {

                    actions.editAsset(asset);
                  }}
                />
              </Suspense>
            )}
          {((state.addAsset && state.isAddAssetSelected) ||
            (state.editAsset && state.isEditAssetSelected)) && (
              <Suspense fallback={<Loading />}>
                <AddEditAsset
                  asset={
                    state.isAddAssetSelected
                      ? state.addAsset
                      : state.editAsset
                  }
                  allAssets={state.assets}
                  isEdit={!state.isAddAssetSelected}
                  fetchAllThingTypes={actions.fetchAllThingTypes}
                  fetchAllTypeCategories={actions.fetchAllThingTypeCategories}
                  fetchAssetImage={actions.getThingImage}
                  fetchAllImages={actions.fetchAllThingImages}
                  handleCancelAction={() => {
                    actions.setSelectedNode(state.selectedAsset.thingId);
                  }}
                  handleAddEditAction={(asset) => {
                    if (state.isAddAssetSelected) {
                      actions
                        .createAsset(asset, state.assets)
                        .then((response) => {
                          if (!response?.error) {

                            actions.setSelectedNode(response.data.thingId);
                          }
                        });
                    } else {
                      /** Update asset details and asset properties action */
                      actions
                        .updateAssetDetails(
                          asset
                        )
                        .then((response) => {
                          if (!response?.error) {
                            actions.setSelectedNode(asset.thing.thingId);
                          }
                        });
                    }
                  }}
                />
              </Suspense>
            )}
        </Tab>

        <Tab eventKey={tabs.alerts.key} title={tabs.alerts.value}>
          <AlertView asset={state.selectedAsset} isTabSelected={activeTab === tabs.alerts.key} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default AssetDetails;

AssetDetails.propTypes = {
  state: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}