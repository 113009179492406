const INFRAMARK_CONSTANTS = {
  assets: {
    tableHeaders: {
      properties: [
        "Property Source Name",
        "Property Name",
        "Property Value",
        "Property Data Type",
        "Property Data Format",
        "Property Label",
        "Property Display Value",
        "Active",
        "Action",
      ],
    },
    tabs: {
      alerts: {
        key: "alerts",
        value: "Alerts",
      },
      assets: {
        key: "assets",
        value: "Assets",
      },
      payload: {
        key: "payload",
        value: "Payload",
      },
    },
    constants: {
      propertyDataTypes: [
        "TEXT",
        "INT",
        "FLOAT",
        "DATE",
        "BIGINT",
        "DOUBLE PRECISION",
        "BOOLEAN",
      ],
    },
    services: {
      assets: "/things",
      assetHierarchy: "/things/thingsByUserId",
      assetDetails: "/things/",
      assetTypes: "/things/type",
      assetTypeDetails: "/things/type/",
      assetImage: "/things/image/imageid/",
      assetTypeImages: "/things/image/list/typeid/",
      createAsset: "/things/create",
      updateAsset: "/things/",
      assetTypeCategories: "/category/thingType/",
      assetTypeCategoryDetails: "/category/",
    },
  },
  alerts: {
    tableHeaders: {
      condition: [
        "Sequence",
        "Information",
        "Category",
        "Condition",
        "Active",
        "Action",
      ],
      configuration: [
        "Alert Name",
        "Alert Parent ID",
        "Alert Tags",
        "Post Processing",
        "Post Processing Schedule",
        "Action",
      ],
    },
    constants: {
      CONFIGURATIONVIEW: "CONFIGURATIONVIEW",
      CONFIGURATIONPREVIEW: "CONFIGURATIONPREVIEW",
      CREATECONFIGURATIONVIEW: "CREATECONFIGURATIONVIEW",
      EDITCONFIGURATIONVIEW: "EDITCONFIGURATIONVIEW",
      CONDITIONPREVIEW: "CONDITIONPREVIEW",
      EDITCONDITIONSVIEW: "EDITCONDITIONSVIEW",
      CREATECONDITIONSVIEW: "CREATECONDITIONSVIEW",
    },
    services: {
      alertConfigurations: "/alert/configs/alerts/",
      alertConfigurationDetails: "/alert/configs/",
      updateAlertConfiguration: "/alert/configs/",
      createAlertConfiguration: "/alert/configs",
      updateAlertCondition: "/alert/conditions/",
      alertConditionDetails: "/alert/conditions/",
      createAlertCondition: "alert/conditions",
    },
  },
  users: {
    tableHeaders: [
      "Group Name",
      "Access To",
      "User Id",
      "Thing",
      "Active",
      "Action",
    ],
    dropdown: {
      roles: [
        { label: "Administrator", value: "Administrator" },
        { label: "User", value: "User" },
      ],
    },
    services: {
      userslist: "/users/usersByGroupId",
      hierarchy: "/things/thingsByGroupId",
      addUserAccess: "/users",
      editUser: "/users/",
      createUser: "/users/keycloak",
      validateUser: "/users/keycloak/",
      validateUserInGroup: "/users/group/keycloak/",
    },
    constants: {
      USERSLISTVIEW: "USERSLISTVIEW",
      ADDUSERVIEW: "ADDUSERVIEW",
      EDITUSERVIEW: "EDITUSERVIEW",
      PASSWORDALERT:`Password must contain at least 1 uppercase letter, 1 special
        character, and 1 digit. The minimum length of the password is 8
        characters.`
    },
  },
  routes: {
    userAdminRoutes: [
      { label: "ASSETS", value: "Assets", route: "/assets" },
      { label: "ADMIN", value: "Admin", route: "/admin" },
      { label: "DASHBOARD", value: "Dashboard", route: "/dashboard" },
      { label: "ALL", value: "All", route: "/home" },
    ],
    superAdminRoutes: [
      { label: "CUSTOMERS", value: "Groups", route: "/groups" },
      { label: "USERS", value: "CreateUser", route: "/createuser" },
      { label: "CONFIGURATIONS", value: "ThingType", route: "/thingtype" },
      { label: "ALL", value: "All", route: "/home" },
    ],
  },
  thingTypeDetails: {
    tableHeaders: {
      thingType: ["Thing Type", "Display Page", "Active", "Action"],
      category: [
        { title: "Category Name", width: "col-4" },
        { title: "Short Description", width: "col-6" },
        { title: "Active", width: "col-1" },
        { title: "Actions", width: "col-1" },
      ],
      alertResponse: [
        { title: "Alert Response", width: "col-2" },
        { title: "Alert Response Message", width: "col-6" },
        { title: "Active", width: "col-1" },
        { title: "Actions", width: "col-1" },
      ],
    },
    constants: {
      THINGTYPELISTVIEW: "THINGTYPELISTVIEW",
      ADDTHINGTYPEVIEW: "ADDTHINGTYPEVIEW",
      EDITTHINGTYPEVIEW: "EDITTHINGTYPEVIEW",
      THINGTYPEPREVIEW: "THINGTYPEPREVIEW",
    },

    displayPages: [
      { label: "Select", value: "" },
      { label: "Main Page", value: "Main Page" },
      { label: "Site", value: "Site" },
      { label: "Equipment(Asset)", value: "Equipment(Asset)" },
      { label: "Sensor", value: "Sensor" },
    ],
    alertResponse: [
      { label: "Acknowledge", value: "A" },
      { label: "Dismiss", value: "D" },
    ],
    services: {
      thingTypeCategoryDetails: "/things/type/details",
      getThingCategoryByID: "things/type/preview/",
      createThingType: "things/type",
      editThingType: "things/type/",
      createThingTypeImage: "things/image",
      thingTypeImages: "/things/image/typeid/",
    },
  },
  groups: {
    tableHeaders: {
      list: ["Group Id", "Group Name", "Active", "Action"],
      kafka: ["Topic Name", "Publisher", "Topic Type", "Active"],
      schema: [
        "Database Type",
        "Database Name",
        "WaterMinds Schema",
        "WaterMinds User Name",
        "AWS Secret Key",
      ],
    },
    dropdown: {
      partition: [
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
      ],
    },
    constants: {
      GROUPLISTVIEW: "GROUPLISTVIEW",
      GROUPCREATEVIEW: "GROUPCREATEVIEW",
      GROUPEDITEVIEW: "GROUPEDITEVIEW",
      GROUPPREVIEW: "GROUPPREVIEW",
    },
    services: {
      groupsList: "/groups",
      editGroup: "/groups/",
      createGroup: "/groups",
      kafkaList: "/groups/kafka/",
      configurationSchemas: "/groups/schemas/",
      editSchema: "/groups/schemas/update/",
      editKafka: "/groups/kafka/update/",
    },
  },
  login: {
    services: {
      userAccessInfo: "/users/byUseridGroupid",
    },
  },
};

export default INFRAMARK_CONSTANTS;
