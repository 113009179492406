import { getRequest, putRequest, postRequest } from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
export const UsersServices = () => {
  const apiPaths = INFRAMARK_CONSTANTS.users.services;
  const services = {
    /** API Call to get all users */
    getAllUsers: async () => {
      const response = await getRequest(`${apiPaths.userslist}`);
      return response;
    },
    getHierarchy: async () => {
      const response = await getRequest(apiPaths.hierarchy);
      return response;
    },
    editUserAccess: async (id, accessInfo) => {
      const response = await putRequest(`${apiPaths.editUser}${id}`, accessInfo);
      return response
    },
    addUserAccess: async (accessInfo) => {
      const response = await postRequest(apiPaths.addUserAccess, accessInfo);
      return response
    },
    validateUser: async (userId) => {
      const response = await getRequest(`${apiPaths.validateUserInGroup}${userId}`);
      return response;
    }
  };
  return services;
};
