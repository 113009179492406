import React, { useContext, useEffect, useState } from "react";
import UserContext from "common/Context/UserContext";
import Modal from "react-bootstrap/Modal";
import "../ThingTypeImage.style.css"
const AddNewImage = ({ show, handleClose, isNewThingType }) => {
  const { userProfile } = useContext(UserContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    isActive: true,
    thingImageName: "",
    image: null,
    thingImageFilename: "",
    createdBy: userProfile.profile.name,
    errors: {},
  });

  const validateFile = (file) => {
    if (!file) return true; // If no file, skip validation
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    const isValidType = validTypes.includes(file.type);
    const isValidSize = file.size <= 1048576; // 1MB

    const errors = {};
    if (!isValidType) {
      errors.image = "Error: File type must be JPG, JPEG, or PNG";
    }
    if (!isValidSize) {
      errors.image = "Error: File size must be less than or equal to 1MB";
    }
    setFormData((prev) => ({ ...prev, errors }));
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    let newValue;
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "file") {
      newValue = files[0];
    } else {
      newValue = value;
    }
    setFormData((prev) => ({ ...prev, [name]: newValue }));

    if (type === "file") {
      if (validateFile(files[0])) {
        setFormData((prev) => ({
          ...prev,
          thingImageFilename: files[0]?.name ?? "",
        }));
      }
    }
  };

  useEffect(() => {
    if (formData.image) {
      const objectUrl = URL.createObjectURL(formData.image);
      setImagePreview(objectUrl);

      // Clean up the object URL when the component unmounts or file changes
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setImagePreview(null);
    }
  }, [formData.image]);

  const onImageSubmit = () => {
    if (validateFile(formData.image)) {
      delete formData["errors"];
      handleClose(formData); // Close the modal after submission
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose(null);
      }}
    >
      <div className="col-12 row">
        <Modal.Header>
          <Modal.Title>Add New Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 row">
            <div className="form-group toggle col-12 row">
              <div className="col-4">
                <label htmlFor="isActive">Active</label>
              </div>
              <div className="col-1">:</div>
              <div className="form-check form-switch col-7">
                <input
                  name="isActive"
                  className="form-check-input custom-switch"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={formData.isActive}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group col-sm-12 row">
              <div className="col-4">
                <label htmlFor="thingImageName">Image Name</label>
              </div>
              <div className="col-1">:</div>
              <div className="col-7">
                <input
                  id="thingImageName"
                  className="form-control"
                  type="text"
                  name="thingImageName"
                  value={formData.thingImageName}
                  onChange={handleChange}
                  required={true}
                  autoComplete="off"
                  autoCorrect="off"
                />
              </div>
            </div>

            <div className="form-group col-sm-12 row">
              <div className="col-4">
                <label htmlFor="image">Select Image</label>
              </div>
              <div className="col-1">:</div>
              <div className="col-7">
                <input
                  name="image"
                  id="image"
                  className="form-control"
                  type="file"
                  accept="image/jpeg, image/png, image/jpg"
                  onChange={handleChange}
                />
                {formData.errors.image && <span style={{color:'red'}}>{formData.errors.image}</span>}
                {imagePreview ? (
                  <div>
                    <img
                      src={imagePreview}
                      alt="Selected"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                ):<span>Image size should be less than 1MB</span>}
              </div>
            </div>

            <div className="form-group col-sm-12 row">
              <div className="col-4">
                <label htmlFor="thingImageFilename">Image Filename</label>
              </div>
              <div className="col-1">:</div>
              <div className="col-7">
                <input
                  id="thingImageFilename"
                  type="text"
                  className="form-control"
                  value={formData.thingImageFilename}
                  readOnly
                />
                {formData.errors.thingImageFilename && (
                  <span>This field is required</span>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 row">
            <div className="col-2"></div>
            <div className="col-10" style={{ textAlign: "end" }}>
              <button
                type="button"
                className="btn btn-light btn-cncl"
                onClick={() => {
                  handleClose(null);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={onImageSubmit}
              >
                {isNewThingType ? "Select" : "Save"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddNewImage;
