import './Loading.css'
import PropTypes from "prop-types";
export default function Loading({loadingText="Loading..."}) {
  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      <p className="spinnerText">{loadingText}</p>
    </div>
  );
}

Loading.propTypes = {
  loadingText: PropTypes.string
}
