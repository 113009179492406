import { useEffect, lazy, Suspense, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import BaseComponent from "common/Components/BaseComponent";
import GroupsList from "./Components/GroupsList";
import { useGroupsViewInitialization } from "./Groups.hook";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import CustomAlert from "common/Components/CustomAlert";
import "./Groups.styles.css";
/** Lazy loading components */
const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));
const AddEditGroup = lazy(() => import("./Components/AddEditGroup"));

const Groups = () => {
  const { state, actions } = useGroupsViewInitialization();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const Constants = INFRAMARK_CONSTANTS.groups.constants;
  useEffect(() => {
    if (state.groupsList === null) {
      actions.getAllGroups();
    }
  }, []);
  if (state.loading) {
    return (
      <Suspense fallback={<Loading />}>
        <Loading />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <BaseComponent>
        {state.error && (
          <Suspense fallback={<Loading />}>
            <ErrorPage
              errorValue={state.error}
              handleGoBack={() => {
                actions.refreshError();
              }}
            />
          </Suspense>
        )}
        {!state.loading && !state.error && (
          <Tabs
            defaultActiveKey="Groups"
            id="uncontrolled-tab-example"
            className="ml-2 mt-2"
          >
            <Tab eventKey="Groups" title="Group Management">
              {state.selectedView === Constants.GROUPLISTVIEW ? (
                <GroupsList
                  groups={state.groupsList?.sort(
                    (a, b) => a.groupId - b.groupId
                  )}
                  handleCreateGroup={() => {
                    actions.changeViewStatus(Constants.GROUPCREATEVIEW, null);
                  }}
                  handelEditGroups={(group) => {
                    actions.changeViewStatus(Constants.GROUPEDITEVIEW, group);
                  }}
                  handelPreviewGroups={(group) => {
                    actions.changeViewStatus(Constants.GROUPPREVIEW, group);
                  }}
                  handleSearchGroup={(searchText) => {
                    actions.searchGroups(searchText);
                  } }
                />
              ) : (
                <Suspense fallback={<Loading />}>
                  <AddEditGroup
                    selectedGroup={state.selectedGroup}
                    selectedView={state.selectedView}
                    allGroups={state.groups}
                    handleAddGroupAction={(groupInfo) => {
                      if (state.selectedView === Constants.GROUPCREATEVIEW) {
                        actions.addNewGroup(
                          groupInfo,
                          Constants.GROUPLISTVIEW,
                          (newGroup) => {
                            actions.updateGroupsList(
                              newGroup,
                              state.groupsList
                            );
                          }
                        );
                      }
                    }}
                    handleEditGroupAction={(groupInfo) => {
                      actions.editGroupInfo(
                        groupInfo,
                        Constants.GROUPLISTVIEW,
                        (editedGroup) => {
                          actions.updateGroupsList(
                            editedGroup,
                            state.groupsList
                          );
                        }
                      );
                    }}
                    handleEditKafkaTopic={(topicInfo) => {
                      actions.editKafkaTopic(
                        topicInfo,
                        state.selectedGroup,
                        (error_message) => {
                          setIsAlertVisible(true);
                          setAlertInfo({
                            title: "Alert",
                            message: error_message,
                          });
                        }
                      );
                    }}
                    handleEditSchemaConfigurations={(schemaInfo) => {
                      actions.editConfigurationSchemas(
                        schemaInfo,
                        state.selectedGroup,
                        (error_message) => {
                          setIsAlertVisible(true);
                          setAlertInfo({
                            title: "Alert",
                            message: error_message,
                          });
                        }
                      );
                    }}
                    handleCancel={() => {
                      actions.changeViewStatus(Constants.GROUPLISTVIEW, null);
                    }}
                    fetchGroupDetails={(selectedGroup) => {
                      actions.fetchKafkaTopicsAndConfigurationsInfo(
                        selectedGroup
                      );
                    }}
                  />
                </Suspense>
              )}
            </Tab>
          </Tabs>
        )}
        {isAlertVisible && (
          <CustomAlert
            show={isAlertVisible}
            title={alertInfo.title}
            message={alertInfo.message}
            onOkay={() => {
              setAlertInfo({});
              setIsAlertVisible(false);
            }}
          />
        )}
      </BaseComponent>
    </Suspense>
  );
};
export default Groups;
