// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-styles-body {
  position: relative;
  top: 65px;
}
`, "",{"version":3,"sources":["webpack://./src/common/Components/BaseComponent/BaseComponent.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".base-styles-body {\n  position: relative;\n  top: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
