import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import { lazy, Suspense, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useThingtypeViewInitialization } from "./ThingType.hook";
import { ThingTypeList, AddEditThingType } from "./Components";
import { useNavigate } from "react-router-dom";
import BaseComponent from "common/Components/BaseComponent";
import CustomAlert from "common/Components/CustomAlert";
/** Lazy loading components */
const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));

const ThingType = () => {
  const { state, actions } = useThingtypeViewInitialization();
  const { constants } = INFRAMARK_CONSTANTS.thingTypeDetails;
  const [showAlert, setShowAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState(null);
  const navigate = useNavigate();
  const uploadNewImage = async (imagesData) => {
    try {
      const response = await actions.addThingTypeImage(imagesData);
      if (response?.error) {
        setAlertInfo({
          title: "Upload Image Error",
          message: "Failed to upload image. Try again!",
        });
        setShowAlert(true);
      }
    } catch (error) {
      setAlertInfo({
        title: "Upload Image Error",
        message: "Failed to upload image. Try again!",
      });
      setShowAlert(true);
    } finally {
      actions.getThingTypes();
    }
  };
  if (state.loading) {
    return (
      <Suspense fallback={<Loading />}>
        <Loading />
      </Suspense>
    );
  }
  if (state.error) {
    return (
      <Suspense fallback={<Loading />}>
        <ErrorPage
          errorValue={state.error}
          handleGoBack={() => {
            navigate("/");
          }}
          buttonTitle="Go Back"
        />
      </Suspense>
    );
  }
  return (
    <BaseComponent>
      <Tabs
        defaultActiveKey="ThingTypeList"
        id="uncontrolled-tab-example"
        className="ml-2 mt-2"
      >
        <Tab eventKey="ThingTypeList" title="Thing Type">
          {state.selectedView === constants.THINGTYPELISTVIEW ? (
            <ThingTypeList
              thingTypes={state.thingTypes} //action items
              fetchAllThingTypes={() => {
                actions.getThingTypes();
              }}
              handleAddNewThingType={() => {
                actions.changeViewStatus(constants.ADDTHINGTYPEVIEW);
              }}
              handleSearchThingType={(searchString) => {
                actions.searchThingType(searchString);
              }}
              handleEditThingType={(thingType) => {
                actions.setSelectedThingType(thingType);
                actions.changeViewStatus(constants.EDITTHINGTYPEVIEW);
              }}
              handlePreviewThingType={(thingType) => {
                actions.setSelectedThingType(thingType);
                actions.changeViewStatus(constants.THINGTYPEPREVIEW);
              }}
            />
          ) : (
            <AddEditThingType
              selectedThingType={state.selectedThingType}
              selectedView={state.selectedView}
              fetchAllImages={actions.fetchAllThingTypeImages}
              isEdit={state.selectedView !== constants.THINGTYPEPREVIEW}
              handleCancel={() => {
                actions.setDefaultSelectedThingType(null);
                actions.changeViewStatus(constants.THINGTYPELISTVIEW);
              }}
              addNewThingType={(data) => {
                actions.addThingType(
                  data,
                  constants.THINGTYPELISTVIEW,
                  (response) => {
                    if (data.images) {
                      const image_data = data.images;
                      image_data.thingTypeId = response.thingTypeId;
                      uploadNewImage(image_data);
                    } else {
                      actions.getThingTypes();
                    }
                  }
                );
              }}
              updateThingType={(data) => {
                actions.editThingType(data, constants.THINGTYPELISTVIEW, () => {
                  actions.getThingTypes();
                });
              }}
              addNewThingTypeImage={actions.addThingTypeImage}
              updateThingTypeImage={actions.updateThingTypeImage}
            />
          )}
        </Tab>
      </Tabs>
      {showAlert && (
        <CustomAlert
          show={showAlert}
          title={alertInfo.title}
          message={alertInfo.message}
          onOkay={() => {
            setShowAlert(false);
            setAlertInfo(null);
          }}
        />
      )}
    </BaseComponent>
  );
};

export default ThingType;
