/** Asset Hierarchy View */
import { useEffect, useState } from "react";
import "./AssetMenu.styles.css";
import AssetHierarchyItem from "./Components/AssetHierarchyItem";
import PropTypes from "prop-types";
import { searchHierarchy } from "common/Utilities";
const AssetMenu = ({
  assets = [],
  selectedAsset,
  handleAssetSelection,
  handleAddNewAsset,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(assets);
  useEffect(() => {
    
    if (searchQuery.length === 0) {
      setFilteredData(assets);
    } else {
      const filteredHeirarchy = searchHierarchy(assets, searchQuery);
      setFilteredData(filteredHeirarchy ? filteredHeirarchy : []);
    }
  }, [searchQuery, assets]);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  /** Build Views */
  const assetHierarchyViewer = () => {
    return (
      <>
        {filteredData.map((asset) => (
          <AssetHierarchyItem
            key={asset.thing_id}
            asset={asset}
            selectedAsset={selectedAsset}
            handleAssetSelection={handleAssetSelection}
            handleAddNewAsset={handleAddNewAsset}
            isParentExpanded={true}
            isSearchEnabled={searchQuery.length > 0}
          />
        ))}
      </>
    );
  };

  return (
    <div className="col-2 asset-menu-nav bg-light">
      <div className="Side-nav-fixed">
        <div className="search-box">
          <input
            type="text"
            className="form-control h5-fontfamily"
            placeholder="Search..."
            onChange={handleSearch}
            value={searchQuery}
            autoComplete="off"
            autoCorrect="off"
          />
        </div>
        <div className="menu">{assetHierarchyViewer()}</div>
      </div>
      <div className="asset-menu-bottom-div col-2"></div>
    </div>
  );
};

export default AssetMenu;

AssetMenu.propTypes = {
  assets: PropTypes.array.isRequired,
  selectedAsset: PropTypes.object,
  handleAssetSelection: PropTypes.func.isRequired,
  handleAddNewAsset: PropTypes.func.isRequired,
};
