import { Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "common/Context/UserContext";
import Home from "pages/Home";
import AssetView from "pages/AssetView";
import Dashboard from "pages/Dashboard";
import PageNotFound from "common/Components/PageNotFound";
import Admin from "pages/Admin/Admin";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import Groups from "pages/Admin/Groups";
import ThingType from "pages/Admin/ThingType";
import CreateUser from "pages/Admin/CreateUser";
import AccessDenied from "common/Components/AccessDenied";
const HomeRoutes = () => {
  const { userProfile } = useContext(UserContext);
  const pages = userProfile.profile.accessTo;
  
  const accessTo = pages.map((key) => key.toLowerCase());
  let page_routes =
    parseInt(userProfile.profile.groupId) === 0
      ? INFRAMARK_CONSTANTS.routes.superAdminRoutes.map((item) => ({
          [item.value]: item.route,
        }))
      : INFRAMARK_CONSTANTS.routes.userAdminRoutes.map((item) => ({
          [item.value]: item.route,
        }));
  page_routes.push({ Home: "/" });
  page_routes.push({AccessDenied: '/access_denied'})
  const filteredPages = page_routes.filter((obj) =>
    Object.keys(obj).some((key) => accessTo.includes(key.toLocaleLowerCase()))
  );
  const accessibleRoutes =
    (accessTo.length === 0 && userProfile.profile.role.toLocaleLowerCase() === "administrator") || accessTo.includes("all")
      ? page_routes
      : filteredPages;
  const isAccessable = (page) => {
    return accessibleRoutes.some((obj) => page in obj);
  };
  return (
    <Routes>
      {accessibleRoutes.length === 1 ? (
        <Route
          path="/"
          element={
            <Navigate
              to={`${Object.values(accessibleRoutes[0])[0]}`}
              replace={true}
            />
          }
        />
      ) : (
        <Route
        path="/"
        element={accessibleRoutes.length > 0 ? <Home /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page. Please contact administrator" showLogout={true}/>}
      />
      )}
      <Route
        path="/home"
        element={accessibleRoutes.length > 0 ? <Home /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/assets"
        element={isAccessable("Assets") ? <AssetView /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/admin"
        element={isAccessable("Admin") ? <Admin /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/dashboard"
        element={isAccessable("Dashboard") ? <Dashboard /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/groups"
        element={isAccessable("Groups") ? <Groups /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/thingtype"
        element={isAccessable("ThingType") ? <ThingType /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
      <Route
        path="/createuser"
        element={isAccessable("CreateUser") ? <CreateUser /> : <PageNotFound statusCode="3" errorText="Access restricted: You do not have permission to view this page."/>}
      />
       <Route
        path="/access_denied"
        element={ <AccessDenied />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default HomeRoutes;
/**
Conditions for logged in users:

 1) If user has multiple page permissions we will take him to the home page
 2) If user has single page permission we will directly take him to the permitted page

 { label: "ASSETS", value: "Assets", route: "/assets" },
    { label: "ADMIN", value: "Admin", route: "/admin" },
    { label: "DASHBOARD", value: "Dashboard", route: "/dashboard" },
    { label: "REPORTS", value: "Reports", route: "/reports" },
    { label: "ALL", value: "All", route: "/home" }
 */
