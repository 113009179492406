import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import { lazy, Suspense } from "react";
import { UsersList, AddEditUser } from "./Components";
import { useUsersViewInitialization } from "./Users.hook";

import "./Users.styles.css";
/** Lazy loading components */
const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));
const Users = () => {
  const { state, actions } = useUsersViewInitialization();
  const { constants } = INFRAMARK_CONSTANTS.users;

  if (state.loading) {
    return (
      <Suspense fallback={<Loading />}>
        <Loading />
      </Suspense>
    );
  }
  if (state.error) {
    return (
      <Suspense fallback={<Loading />}>
        <ErrorPage
          errorValue={state.error}
          handleGoBack={() => {
            actions.removeError();
            actions.changeView(constants.USERSLISTVIEW);
          }}
          buttonTitle="Go Back"
        />
        ;
      </Suspense>
    );
  }
  return (
    <>
      {state.selectedView === constants.USERSLISTVIEW ? (
        <UsersList
          users={state.usersList}
          handleAddNewUser={() => {
            actions.changeView(constants.ADDUSERVIEW);
          }}
          handleSearchUser={(searchString) => {
            actions.searchUsers(searchString);
          }}
          handleEditUser={(user) => {
            actions.editUser(user);
          }}
          fetchUsers={() => {
            actions.getAllUsers();
          }}
        />
      ) : (
        <AddEditUser
          selectedUser={state.selectedUser}
          isEdit={state.selectedView === constants.EDITUSERVIEW}
          handleCancel={() => {
            actions.setSelectedUser(null);
            actions.changeView(constants.USERSLISTVIEW);
          }}
          getHierarchyList={actions.getHierarchyList}
          getGroupDetails={actions.fetchGroupDetails}
          updateUserAccess={actions.updateUserAccessInfo}
          addNewUserAccess={actions.addNewUserAccess}
          hierarchylist={state.hierarchylist}
          validateUser={actions.validateUser}
        />
      )}
    </>
  );
};

export default Users;
