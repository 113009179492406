import { useState } from "react";
import "./Admin.styles.css";
import { Tabs, Tab } from "react-bootstrap";
import BaseComponent from "common/Components/BaseComponent";
import Users from "./Users";


const Admin = () => {
  const [activeTab, setActiveTab] = useState("usermanagement");
  return (
    <BaseComponent>
      <Tabs
        defaultActiveKey="usermanagement"
        id="uncontrolled-tab-example"
        className="ml-2 mt-2"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
      >
        <Tab eventKey="usermanagement" title="User Access">
          <Users />
        </Tab>

      </Tabs>
    </BaseComponent>
  );
};
export default Admin;
