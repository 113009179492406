import { getRequest, putRequest, postRequest, getAllRequests } from "common/HttpClient/http-utility";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
export const GroupsServices = () => {
  const apiPaths = INFRAMARK_CONSTANTS.groups.services;
  const services = {
    /** API Call to get all users */
    getAllGroups: async () => {
      const response = await getRequest(`${apiPaths.groupsList}`);
      return response;
    },
    editGroup: async ( groupInfo) => {
      const response = await putRequest(`${apiPaths.editGroup}${groupInfo.groupId}`, groupInfo);
      return response
    },
    addNewGroup: async (groupInfo) => {
      const response = await postRequest(apiPaths.createGroup, groupInfo);
      return response
    },
  
    getKafkaAndConfigurationsInfo: async (
     groupId
    ) => {
      let urls = [`${apiPaths.kafkaList}${groupId}`,`${apiPaths.configurationSchemas}${groupId}`];
      const responses = await getAllRequests(urls);
      return responses;
    },
    editKafkaTopic: async (topic) => {
      const response = await putRequest(`${apiPaths.editKafka}${topic.groupId}`, [topic]);
      return response
    },
    editConfigurationSchema: async (schemaInfo) => {
      const response = await putRequest(`${apiPaths.editSchema}${schemaInfo.groupId}`, [schemaInfo]);
      return response
    },
  };
  return services;
};
