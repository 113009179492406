import { useState, useMemo } from "react";
import UserContext from "./UserContext";
import { INITIAL_USER_STATE } from "./Context.model";
import { keycloak, setTokenToHeader, clearKeycloakData } from "common/HttpClient";
import PropTypes from "prop-types";
const PROFILE = "profile";

const UserContextProvider = ({children}) => {
    const [userProfile, setUserProfile] = useState(INITIAL_USER_STATE);
	const loadUserInformation = (profile) => {
		if(!profile) {
		  return
		}
		profile.token = localStorage.getItem("keycloak-token")
		localStorage.setItem(PROFILE, JSON.stringify(profile));
		setTokenToHeader(profile.groupId, profile.userid, profile.token) 
		setUserProfile({
		isLoggedin: true,
		  profile,
		});
	  };

    const logout = () => {
		keycloak.logout()
		clearKeycloakData()
		localStorage.setItem(PROFILE, null);
		setTokenToHeader('')
		setUserProfile(INITIAL_USER_STATE);
	};
	
	const getUserProfile = () => {
		const userData = localStorage.getItem(PROFILE);
		try {
		  const parsedUserData = JSON.parse(userData);
		  if (parsedUserData) {
			 setTokenToHeader(parsedUserData.groupId)
			setUserProfile({
			 isLoggedin: true,
			  profile: parsedUserData,
			});
		  }
		} catch {
			logout()
		}
	}
    const contextValues = useMemo(() => {
		return {
			userProfile,
			getUserProfile,
			loadUserInformation,
			logout,
		};
	});
	return (
		<UserContext.Provider value={contextValues}>{children}</UserContext.Provider>
	);
}

export default UserContextProvider

UserContextProvider.propTypes = {
	children: PropTypes.any
}