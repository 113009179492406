// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  background: var(--theme-color) !important;
  color: white !important;
  width: 200px;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  width: 200px;
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  width: 200px;
  text-align: left;
  border-color: var(--bs-nav-tabs-link-hover-border-color) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Admin.styles.css"],"names":[],"mappings":"AAAA;;EAEE,2CAA2C;EAC3C,mDAAmD;EACnD,yDAAyD;EACzD,yCAAyC;EACzC,uBAAuB;EACvB,YAAY;AACd;AACA;;EAEE,kBAAkB;EAClB,wDAAwD;EACxD,YAAY;AACd;AACA;EACE,yDAAyD;EACzD,yDAAyD;EACzD,wDAAwD;EACxD,yDAAyD;EACzD,YAAY;EACZ,gBAAgB;EAChB,mEAAmE;AACrE","sourcesContent":[".nav-tabs .nav-item.show .nav-link,\n.nav-tabs .nav-link.active {\n  color: var(--bs-nav-tabs-link-active-color);\n  background-color: var(--bs-nav-tabs-link-active-bg);\n  border-color: var(--bs-nav-tabs-link-active-border-color);\n  background: var(--theme-color) !important;\n  color: white !important;\n  width: 200px;\n}\n.nav-tabs .nav-link:hover,\n.nav-tabs .nav-link:focus {\n  isolation: isolate;\n  border-color: var(--bs-nav-tabs-link-hover-border-color);\n  width: 200px;\n}\n.nav-tabs .nav-link {\n  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));\n  border: var(--bs-nav-tabs-border-width) solid transparent;\n  border-top-left-radius: var(--bs-nav-tabs-border-radius);\n  border-top-right-radius: var(--bs-nav-tabs-border-radius);\n  width: 200px;\n  text-align: left;\n  border-color: var(--bs-nav-tabs-link-hover-border-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
