// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-title-display {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h5-no-img {
  text-align: center;
  margin-top: 15px !important;
}

.custom-switch {
  width: 45px !important; /* Increase width */
  height: 25px !important; /* Increase height */
  border-radius: 34px !important; /* Ensure it looks rounded */
  border-color: lightgray !important;
}

.custom-switch:checked {
  background-color: #34c759 !important; /* Change background color when checked */
}

.custom-switch:focus {
  box-shadow: none !important; /* Remove the default focus outline */
}

/* Adjust the slider */
.custom-switch:before {
  background-color: gray !important; /* Color of the switch knob */
}

.custom-switch:checked:before {
  transform: translateX(26px) !important; /* Move the knob to the right when checked */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/ThingType/Components/ThingTypeImage/ThingTypeImage.style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB,EAAE,mBAAmB;EAC3C,uBAAuB,EAAE,oBAAoB;EAC7C,8BAA8B,EAAE,4BAA4B;EAC5D,kCAAkC;AACpC;;AAEA;EACE,oCAAoC,EAAE,yCAAyC;AACjF;;AAEA;EACE,2BAA2B,EAAE,qCAAqC;AACpE;;AAEA,sBAAsB;AACtB;EACE,iCAAiC,EAAE,6BAA6B;AAClE;;AAEA;EACE,sCAAsC,EAAE,4CAA4C;AACtF","sourcesContent":[".image-title-display {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.h5-no-img {\n  text-align: center;\n  margin-top: 15px !important;\n}\n\n.custom-switch {\n  width: 45px !important; /* Increase width */\n  height: 25px !important; /* Increase height */\n  border-radius: 34px !important; /* Ensure it looks rounded */\n  border-color: lightgray !important;\n}\n\n.custom-switch:checked {\n  background-color: #34c759 !important; /* Change background color when checked */\n}\n\n.custom-switch:focus {\n  box-shadow: none !important; /* Remove the default focus outline */\n}\n\n/* Adjust the slider */\n.custom-switch:before {\n  background-color: gray !important; /* Color of the switch knob */\n}\n\n.custom-switch:checked:before {\n  transform: translateX(26px) !important; /* Move the knob to the right when checked */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
