// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-top {
  padding: 5px 5px 5px 15px !important;
  height: 65px;
  position: fixed !important;
  top: 0 !important;
  z-index: 100;
  width: 100%;
}

.user-info {
  font-size: 16px;
  color: var(--txt-white-color);
  text-align: end;
}
.user-role {
  font-size: 14px;
  font-weight: normal !important;
}
.user-logo {
  font-size: 14px;
  color: var(--txt-white-color);
  margin-left: 14px;
}

.header-bg {
  background-color: var(--theme-color);
}
.nav-header-font {
  font-size: 18px;
  padding: 0px;
  font-weight: normal;
 color: var(--txt-white-color);
}
.div-nav {
  margin-left: 25px;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color:var(--txt-infra-color);
  background: #e2edf1;
  border-radius: 10px;
  font-weight: 500;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color:var(--txt-infra-color);
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    color: var(--txt-white-color);
}
.user-info-dropdown{
  left: -80px !important;
}`, "",{"version":3,"sources":["webpack://./src/common/Components/Header/Header.styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,YAAY;EACZ,0BAA0B;EAC1B,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,eAAe;EACf,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;CACpB,6BAA6B;AAC9B;AACA;EACE,iBAAiB;AACnB;AACA;;;;EAIE,4BAA4B;EAC5B,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,kDAAkD;IAClD,iDAAiD;IACjD,6BAA6B;AACjC;AACA;EACE,sBAAsB;AACxB","sourcesContent":[".header-top {\n  padding: 5px 5px 5px 15px !important;\n  height: 65px;\n  position: fixed !important;\n  top: 0 !important;\n  z-index: 100;\n  width: 100%;\n}\n\n.user-info {\n  font-size: 16px;\n  color: var(--txt-white-color);\n  text-align: end;\n}\n.user-role {\n  font-size: 14px;\n  font-weight: normal !important;\n}\n.user-logo {\n  font-size: 14px;\n  color: var(--txt-white-color);\n  margin-left: 14px;\n}\n\n.header-bg {\n  background-color: var(--theme-color);\n}\n.nav-header-font {\n  font-size: 18px;\n  padding: 0px;\n  font-weight: normal;\n color: var(--txt-white-color);\n}\n.div-nav {\n  margin-left: 25px;\n}\n.navbar-light .navbar-nav .active > .nav-link,\n.navbar-light .navbar-nav .nav-link.active,\n.navbar-light .navbar-nav .nav-link.show,\n.navbar-light .navbar-nav .show > .nav-link {\n  color:var(--txt-infra-color);\n  background: #e2edf1;\n  border-radius: 10px;\n  font-weight: 500;\n}\n.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {\n    color:var(--txt-infra-color);\n}\n.navbar-expand .navbar-nav .nav-link {\n    padding-right: var(--bs-navbar-nav-link-padding-x);\n    padding-left: var(--bs-navbar-nav-link-padding-x);\n    color: var(--txt-white-color);\n}\n.user-info-dropdown{\n  left: -80px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
