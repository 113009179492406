import { useEffect, useContext, useState } from "react";
import UserContext from "common/Context/UserContext";
import "./App.css";
import RootRouter from "common/Navigations/RootRouter";
import "./assets/Fonts/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "common/Components/Loading";
import ErrorPage from "common/Components/ErrorPage";
import { getUserAccessInformation } from "common/Utilities/Utilities.functions";



function App({ profile }) {
  const [loading, setLoading] = useState(false);
  const [profileError, setProfileError] = useState(null);
  const { loadUserInformation, logout } = useContext(UserContext);

  useEffect(() => {
    loadUserInformation(profile);
    const fetchUserAccessInfo = async () => {
      setLoading(true);
      try {
        const response = await getUserAccessInformation();
        if (response.status === 200) {
          if (response?.data?.length <= 0 && profile.role.toLocaleLowerCase() === "administrator") {
            profile.accessTo = ["all"];
            loadUserInformation(profile);
            setProfileError(null);
          } else {
            const access_filter_array = response.data.filter(
              (user) => user.isActive
            );
            if (response.data.length > 0 && access_filter_array.length === 0) {
              profile.accessTo = ["AccessDenied"];
            } else {
              const access_info_array = access_filter_array.map(
                (user) => user.accessTo
              );
              const flattenedArray = access_info_array.flatMap((item) =>
                item.split(",")
              );
              const user_access_info = [...new Set(flattenedArray)];
              profile.accessTo = user_access_info;
            }
            loadUserInformation(profile);
            setProfileError(null);
          }
        } else {
          setProfileError("Failed to fetch user access information");
        }
      } catch {
        setProfileError("Failed to fetch user access information");
      }
      setLoading(false);
    };
    if (parseInt(profile.groupId) !== 0) {
      fetchUserAccessInfo();
    } else {
      profile.accessTo = ["all"];
      loadUserInformation(profile);
      setProfileError(null);
    }
  }, [profile]);
  if (loading) {
    return <Loading loadingText="Loading User Information...." />;
  }
  if (profileError) {
    return <ErrorPage errorValue={profileError} handleGoBack={()=>{
      logout()
    }} buttonTitle="Logout"/>;
  }
  return (
    <div>
      <RootRouter />
    </div>
  );
}

export default App;
