// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-tooltip {
    background-color: #f8f7f3; /* Change this to your desired color */
    color: #d41818 !important; /* Tooltip text color */
}

#button-tooltip > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
    text-align: left;
  }
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/CreateUser/CreateUser.styles.css"],"names":[],"mappings":";AACA;IACI,yBAAyB,EAAE,sCAAsC;IACjE,yBAAyB,EAAE,uBAAuB;AACtD;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB","sourcesContent":["\n.custom-tooltip {\n    background-color: #f8f7f3; /* Change this to your desired color */\n    color: #d41818 !important; /* Tooltip text color */\n}\n\n#button-tooltip > .tooltip-inner {\n    background-color: #fff;\n    color: #000;\n    border: 1px solid #062e56;\n    text-align: left;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
