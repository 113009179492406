import { lazy, Suspense } from "react";
import { useAlertViewInitialization } from "./AlertView.hooks";
import INFRAMARK_CONSTANTS from "common/Constants/inframark-constants";
import { AlertConfigurations } from "./Components";
import PropTypes from "prop-types";

const Loading = lazy(() => import("common/Components/Loading"));
const ErrorPage = lazy(() => import("common/Components/ErrorPage"));
const AlertConfigurationDetails = lazy(() =>
  import("./Components/AlertConfigurationDetails/AlertConfigurationDetails")
);
const AddEditAlertCondition = lazy(() =>
  import("./Components/AlertCondition/AddEditAlertCondition")
);
const AlertView = ({ asset, isTabSelected }) => {
  const Constants = INFRAMARK_CONSTANTS.alerts.constants;
  const { state, actions } = useAlertViewInitialization();
  if (state.loading) {
    return (
      <Suspense fallback={<Loading />}>
        <Loading />
      </Suspense>
    );
  }
  if (state.error) {
    return (
      <Suspense fallback={<Loading />}>
        <ErrorPage errorValue={state.error} handleGoBack={()=>{
          actions.refreshError()
        }}/>
      </Suspense>
    );
  }
  if (!isTabSelected || asset === null) {
    return <></>;
  }
  const loadView = () => {
    if (state.viewStatus === Constants.CONFIGURATIONVIEW) {
      return (
        <AlertConfigurations
          alerts={state.alertConfigurations}
          parentName={asset.thingName}
          fetchAlertConfigurations={() => {
            actions.getAllAlertConfigurations(asset.thingId);
          }}
          handleAddConfiguration={() => {
            //Change View and pass thingId
            actions.setSelectedAlertConfiguration(null);
            actions.changeViewStatus(Constants.CREATECONFIGURATIONVIEW);
          }}
          handlePreviewConfiguration={(alert) => {
            //Change View and pass alert
            actions.setSelectedAlertConfiguration(alert);
            actions.changeViewStatus(Constants.CONFIGURATIONPREVIEW);
          }}
          handleEditConfiguration={(alert) => {
            //Change View and pass alert
            actions.setSelectedAlertConfiguration(alert);
            actions.changeViewStatus(Constants.EDITCONFIGURATIONVIEW);
          }}
          isTabSelected={isTabSelected}
        />
      );
    } else if (
      state.viewStatus === Constants.CONFIGURATIONPREVIEW ||
      state.viewStatus === Constants.EDITCONFIGURATIONVIEW ||
      state.viewStatus === Constants.CREATECONFIGURATIONVIEW
    ) {
      return (
        <Suspense fallback={<Loading />}>
          <AlertConfigurationDetails
            data={{
              alert: state.selectedAlertConfiguration,
              parentObject: asset,
              status: state.viewStatus,
            }}
            handleGoBackAction={() => {
              actions.changeViewStatus(Constants.CONFIGURATIONVIEW);
              actions.getAllAlertConfigurations(asset.thingId);
            }}
            handleChangeViewStatus={(status) => {
              if (status === Constants.CREATECONFIGURATIONVIEW) {
                actions.setSelectedAlertConfiguration(null);
              }
              actions.changeViewStatus(status);
            }}
            fetchAlertConfigurationDetails={(alertConfigId) => {
              actions.getAlertConfigurationDetails(alertConfigId);
            }}
            handleSubmit={(data) => {
              if (state.viewStatus === Constants.CREATECONFIGURATIONVIEW) {
                // Create api
                actions.createAlertConfiguration(
                  data,
                  Constants.CONFIGURATIONPREVIEW
                );
              } else if (state.viewStatus === Constants.EDITCONFIGURATIONVIEW) {
                //Edit API
                actions.updateAlertConfiguration(
                  data,
                  Constants.CONFIGURATIONPREVIEW
                );
              }
            }}
            handleAddEditPreviewCondition={(status, condition, activeTab) => {
              actions.setSelectedCategory(activeTab);
              actions.setSelectedAlertCondition(condition);
              actions.changeViewStatus(status);
            }}
          />
        </Suspense>
      );
    } else if (
      state.viewStatus === Constants.CONDITIONPREVIEW ||
      state.viewStatus === Constants.EDITCONDITIONSVIEW ||
      state.viewStatus === Constants.CREATECONDITIONSVIEW
    ) {
      return (
        <Suspense fallback={<Loading />}>
          <AddEditAlertCondition
            configDetails={state.selectedAlertConfiguration}
            condition={state.selectedAlertCondition}
            category={state.selectedCategory}
            typeId={asset.thingTypeId}
            fetchAlertConditionDetails={actions.getAlertConditionDetails}
            handleGoBackAction={(status) => {
              actions.changeViewStatus(status);
              if (status === Constants.CONFIGURATIONVIEW) {
                actions.getAllAlertConfigurations(asset.thingId);
              }
            }}
            handleCancelCondition={() => {
              actions.changeViewStatus(Constants.CONFIGURATIONPREVIEW);
            }}
            handleSubmitAction={(condition_data) => {
              if (state.viewStatus === Constants.CREATECONDITIONSVIEW) {
                actions
                  .createnewAlertCondition(
                    condition_data,
                    Constants.CONFIGURATIONPREVIEW
                  )
                  .then(() => {
                    actions.getAlertConfigurationDetails(
                      state.selectedAlertConfiguration.alertConfigId
                    );
                  });
              } else {
                actions
                  .updateAlertCondition(
                    condition_data,
                    Constants.CONFIGURATIONPREVIEW
                  )
                  .then(() => {
                    actions.getAlertConfigurationDetails(
                      state.selectedAlertConfiguration.alertConfigId
                    );
                  });
              }
            }}
            status={state.viewStatus}
            fetchAllImages={actions.fetchAllThingImages}
            fetchAssetImage={actions.fetchIconImageById}
          />
        </Suspense>
      );
    } else {
      return <></>;
    }
  };
  return <div>{loadView()}</div>;
};

export default AlertView;

AlertView.propTypes = {
  asset: PropTypes.object,
  isTabSelected: PropTypes.bool.isRequired,
};
