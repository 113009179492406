import inframarkLogo from "../../../../assets/logo_inframark.webp";
import { useNavigate } from "react-router-dom";
const BrandLogo = () => {
  const navigate = useNavigate();
  return (
    <div className="navbar-brand">
      <button
        className="brand-btn-without-bg"
        style={{ border: "none", background: "none" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          src={inframarkLogo}
          alt="Inframark Logo"
          style={{ width: "90%" }}
        />
      </button>
    </div>
  );
};

export default BrandLogo;
