// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-menu-nav {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: "InframarkFont", Arial, Helvetica, sans-serif !important;
  font-size: 18px;
  line-height: 30px;
  padding-right: 0 !important;
}
.search-hierarchy:hover {
  text-decoration: underline;
}
.menu {
  overflow: auto;
  height: calc(100vh - 20vh);
  scrollbar-width: thin;
}
.asset-menu-bottom-div {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.search-box {
  padding: 10px 5px 10px 0px;
  padding-left: 10px;
}
.Side-nav-fixed {
  border-right: 1px solid #e7e7e7;
  --bs-bg-opacity: 1;
  background-color: rgb(246 250 252) !important;
  left: 0;
  padding-left: 2px;
  position: fixed;
  white-space: normal;
  width: 16.8%;
  z-index: 5;
  bottom: 0;
  top: 65px;
}
.h5-fontfamily {
  font-family: "InframarkFont", Arial, Helvetica, sans-serif !important;
}
.Ul-search {
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AssetView/Components/AssetMenu/AssetMenu.styles.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,kBAAkB;EAClB,qEAAqE;EACrE,eAAe;EACf,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,cAAc;EACd,0BAA0B;EAC1B,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,aAAa;AACf;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,6CAA6C;EAC7C,OAAO;EACP,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,SAAS;AACX;AACA;EACE,qEAAqE;AACvE;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".asset-menu-nav {\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);\n  position: relative;\n  font-family: \"InframarkFont\", Arial, Helvetica, sans-serif !important;\n  font-size: 18px;\n  line-height: 30px;\n  padding-right: 0 !important;\n}\n.search-hierarchy:hover {\n  text-decoration: underline;\n}\n.menu {\n  overflow: auto;\n  height: calc(100vh - 20vh);\n  scrollbar-width: thin;\n}\n.asset-menu-bottom-div {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  z-index: 1000;\n}\n.search-box {\n  padding: 10px 5px 10px 0px;\n  padding-left: 10px;\n}\n.Side-nav-fixed {\n  border-right: 1px solid #e7e7e7;\n  --bs-bg-opacity: 1;\n  background-color: rgb(246 250 252) !important;\n  left: 0;\n  padding-left: 2px;\n  position: fixed;\n  white-space: normal;\n  width: 16.8%;\n  z-index: 5;\n  bottom: 0;\n  top: 65px;\n}\n.h5-fontfamily {\n  font-family: \"InframarkFont\", Arial, Helvetica, sans-serif !important;\n}\n.Ul-search {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
