import axios from 'axios';
import { keycloak } from './key-cloack';
const httpClient = axios.create({
  baseURL: "/api",
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
// Add a response interceptor
httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 errors (e.g., log out the user)
      localStorage.clear()
      keycloak.logout()
      
      // Optionally: You may also want to clear other user-related state here
    }
    return Promise.reject(error instanceof(Error) ? error : Error("unknown error"));
  }
);
const setTokenToHeader = (groupid, userid, token) => {
  httpClient.defaults.headers.common['group-id'] = groupid
  httpClient.defaults.headers.common['user-id'] = userid
  httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
};
export  {
    httpClient,
    setTokenToHeader
}